import { motion } from 'framer-motion'

const MotionItem = ({ children, Tag=motion.div, ...props }) => {
    return (
      <Tag 
        layout
        transition={{ duration: 0.3 }}
        {...props}
        >
          {children}
      </Tag>
    );
  };

export default MotionItem;