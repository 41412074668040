import { apiUrl, filesUrl } from 'constants/urls'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useUserContext } from 'store/user-context'
import { defaultDesignMobile, defaultDesignPC, defaultGeneralDesign, swiperDefaultDesign } from '../constants'
import { useBrowserContext } from 'store/browser-context'
import { darkenHexColor, hexToRgb, mergeObjects, translate } from 'utils/utils'
import colors from '../../../../../json/storeColors.json'

const storeColors = colors.colors

const CustomizeHomePageContext = createContext({
    sections: [], 
    setSections: ()=>{},
    selectedSectionId : 'general-design', 
    selectedSectionType: '',
    setselectedSectionId: ()=>{},
    updateSectionDesign: (id, newDesign)=>{},
    selectedDevice: 'mobile', 
    setSelectedDevice: ()=>{},
    isMobile: true,
    selectedSectionDesign : {},
    store: {},
    primaryColor : null,
    visionMode: localStorage.getItem("theme"),
    generalDesign: {},
    updateGeneralDesign: ()=>{},
    fullScreen: false, 
    setFullScreen: ()=>{}
})


export const getDefaultProductsContainerDesign = (data, browserContext)=>{
  const {colors}=browserContext
  const color = {
    light: data.store.primaryColor,
    dark: data.store.primaryColorDark
  }

  defaultDesignPC.title.label.color = defaultDesignMobile.title.label.color = color

  defaultDesignPC.products.product.title.color = defaultDesignMobile.products.product.title.color = {
    light: colors.textColor,
    dark: colors.containerColor
  }

  defaultDesignPC.products.product.price.color = defaultDesignMobile.products.product.price.color = color
  defaultDesignMobile.products.bordersRounded = defaultDesignPC.products.bordersRounded = data.store.bordersRounded
  return ({
    'mobile': defaultDesignMobile,
    'PC': defaultDesignPC
  })
}

window.storeColors = {}

const InnerCustomizeHomePageContextProvider = ({children, homePageMode, setHomePageMode})=>{
    const [sections, setSections] = useState(null)
    const [store, setStore] = useState(null)
    const {userData} = useUserContext()
    const [generalDesign, setGeneralDesign] = useState(defaultGeneralDesign)

    const browserContext = useBrowserContext()
    
    const updateSections=()=>{
      fetch(
        apiUrl + '/store/home-customization-products?domain='+ userData.domain,
        {
          headers: {
            'Accept-Language': localStorage.getItem('language')
          }
        }
      ).then((response)=>{
        response.json().then(data=>{
          let newSections = data.sections.map(sec=>{
            if (sec.type === 'products-container' || sec.type === 'category'){
              if(!sec.design){
                return   {
                  ...sec,
                  design: getDefaultProductsContainerDesign(data, browserContext)
                }
                
              }
              else return {
                ...sec,
                design: mergeObjects(getDefaultProductsContainerDesign(data, browserContext), sec.design)
              }
            } 
            else if (sec.type === 'swiper'){
              return {
                ...sec,
                design: mergeObjects(swiperDefaultDesign['mobile'], sec.design)
              }

            }
            else return sec
          })
          setSections(newSections)

          setHomePageMode(data.home_page_mode)
          window.storeData = data.store
          setStore(data.store)
          data.generalDesign && setGeneralDesign(data.generalDesign)
        })
      })
    }

    const timeOutDone = useRef(false)

    useEffect(()=>{
      updateSections()
      setTimeout(()=>{
        timeOutDone.current = true
      }, 100)
    }, [])

    useEffect(()=>{
      if (timeOutDone.current) updateSections()
    }, [homePageMode])

    const [visionMode, setVisonMode] = useState()
    useEffect(()=>{
      if (store){
        setVisonMode((store.visionMode === 'auto') ? localStorage.getItem("theme") : store.visionMode)
      }
    }, [store])

    const [selectedDevice, setSelectedDevice] = useState('mobile')
    useEffect(()=>{
      if(window.storeData){
        const sectionsContainer = document.getElementById('sections-container')
        console.log(sectionsContainer)
        if (sectionsContainer){
          const primary = window.storeData['primaryColor']
          const primaryDark = window.storeData['primaryColorDark'] 
          const newColors = storeColors[visionMode]
          for (let key in newColors) {
              sectionsContainer.style.setProperty(`--${key}`, newColors[key])
              sectionsContainer.style.setProperty(`--${key}-rgb`, hexToRgb(newColors[key]))
          }
          sectionsContainer.style.setProperty('--primaryColor', visionMode === 'light' ? primary: primaryDark)
          sectionsContainer.style.setProperty('--primaryColor-rgb', visionMode === 'light' ? hexToRgb(primary): hexToRgb(primaryDark))
          sectionsContainer.style.setProperty('--primary100Color', visionMode === 'light' ? darkenHexColor(primary, 20): darkenHexColor(primaryDark, -20))     
          window.storeColors = {
            ...newColors,
            primaryColor: visionMode === 'light' ? primary: primaryDark,
            primaryColor100: visionMode === 'light' ? darkenHexColor(primary, 20): darkenHexColor(primaryDark, -20),
          }
        }
      }
    }, [visionMode, selectedDevice])
  
    const [selectedSectionId, setselectedSectionId] = useState('general-design')

    useEffect(()=>{
        document.documentElement.classList.add('no-scroll')
        return ()=> document.documentElement.classList.remove('no-scroll')
    }, [])


    
    const selectedSection = sections?.find(sec=>sec.id === selectedSectionId)
    let selectedSectionDesign = selectedSection?.design

    const iseSelectedSectionProductsContainer = selectedSection?.type === 'products-container' || selectedSection?.type === 'category'
    const iseSelectedSectionSwiper = selectedSection?.type === 'swiper'
    selectedSectionDesign = selectedSectionDesign ? 
      (
        iseSelectedSectionProductsContainer ? selectedSectionDesign[selectedDevice] :
        iseSelectedSectionSwiper ? selectedSectionDesign :
        {}
      ):  
      {}

    const updateSectionDesign=(id, newDesign)=>{
      setSections(sections=>{
        const newState = [...sections]
        const section = newState.find(elem=>elem.id === id)
        if (iseSelectedSectionProductsContainer){
          section.design[selectedDevice] = {
            ...section.design[selectedDevice],
            ...newDesign
          }
        }else if (iseSelectedSectionSwiper){
          section.design= {
            ...section.design,
            ...newDesign}
        }
        return newState
      })        
    }
    const isMobile = selectedDevice === 'mobile'
    
    const updateGeneralDesign = (newGeneralDesign)=>{
      setGeneralDesign({
        ...generalDesign,
        [selectedDevice]: newGeneralDesign
      })
    }

    const [fullScreen, setFullScreen] = useState(false)

    const value={
        sections,
        setSections,
        selectedSectionId, 
        selectedSectionType: selectedSection?.type,
        setselectedSectionId,
        updateSectionDesign,
        selectedDevice, setSelectedDevice,
        isMobile,
        selectedSectionDesign,
        store,
        visionMode,
        generalDesign: generalDesign[selectedDevice],
        generalDesignObject: generalDesign,
        updateGeneralDesign,
        fullScreen, setFullScreen,
        homePageMode, setHomePageMode
    }

    return(
        <CustomizeHomePageContext.Provider value={value}>
            {sections && children}
        </CustomizeHomePageContext.Provider>
    )
}

const CustomizeHomePageContextProvider = ({children})=>{
  const [homePageMode, setHomePageMode] = useState(true)

  return <InnerCustomizeHomePageContextProvider {...{homePageMode, setHomePageMode}}>{children}</InnerCustomizeHomePageContextProvider>
}

export default CustomizeHomePageContextProvider
export const useCustomizeHomePageContext = ()=>useContext(CustomizeHomePageContext)