import React from 'react'
import classes from './Header.module.css'
import IconWithHover from '../IconWithHover'
import { useBrowserContext } from '../../store/browser-context'
import GlobalMessage from '../notifications/GlobalMessage'
import logo from '.././../assets/logo.png'
import {useUserContext} from '../../store/user-context'
import UserButton from './UserButton'
import useGetCurrentScreenWidth from 'hooks/useGetScreenWidth'
import Button from 'components/Button'

const Header = () => {
  const {globalMessageA, setOpenSideBar, setLang, lang}=useBrowserContext()
  const {userData}= useUserContext()
  const getCurrentScreenWidth = useGetCurrentScreenWidth()
  const isMobile = getCurrentScreenWidth <= 580

  const languages = [{id: 'fr', label: 'Fr'}, {id: 'ar', label: 'ع'}, {id: 'en', label: 'En'}]
  return (
    <>
      <header id='header' style={{position: 'sticky', top: 0, zIndex:1002}}>
        <div className={classes.header}>
          <div className='d-flex g-3 align-items-center'>
            { userData && <IconWithHover size={28} iconClass="fa-solid fa-bars" color={'var(--primaryColor)'} onClick={()=>setOpenSideBar(true)} />}
            
            {!isMobile && 
            <>
              <span/>
              <span/>
              {
                languages.map(innerLang=>{
                  return <Button onClick={()=>setLang(innerLang.id)} style={{padding: '0', width: 42}} outline={innerLang.id === lang} key={innerLang.id}>{ innerLang.label }</Button>
                })
              }
            </>}
          </div>
          <button><img src={logo} width={120} /></button>
          <div>
            <UserButton />
          </div>
        </div>
        <div  style={{position: 'sticky', top: 0}}>
          {globalMessageA && <GlobalMessage children={globalMessageA.children} />}
        </div>
      </header>
      <div style={{position: 'sticky', top: 0, zIndex:1001}}>
        {globalMessageA && <GlobalMessage children={globalMessageA.children} />}
      </div>
    </>
    
  )
}

export default Header