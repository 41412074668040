import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import classes from '../AddProduct.module.css'
import { axiosTr, deleteImage, fileToBase64, reduceImageQuality, translate } from 'utils/utils'
import IconWithHover from 'components/IconWithHover'
import Button from 'components/Button'
import Loader from '../../components/Loader'
import { filesUrl } from 'constants/urls'
import MotionItem from 'components/Motionitem'
import { useBrowserContext } from 'store/browser-context'
import { useContextSelector } from 'use-context-selector'
import { AddProductContext } from './store/add-product-context'
import DialogComponent from 'components/tags/Dialog'
import Drag2D from 'components/drag-2d/Drag2D'

const ImageCard =({file, deleteImageHandler, index})=>{
  const {colors} = useBrowserContext()
return(
  <div disabled={file.status === 'deleting'} className='p-1 border d-f g-3 justify-space-between' style={{
    backgroundColor: colors['backgroundColor'],
    border: '1px solid ' + colors['borderColor']
  }} >
    <div
      style={{
        backgroundImage: `url(${ file.base64Url || file.imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 80,
        width: 80,
        borderRadius: 4,
        border: '1px solid ' + colors['borderColor']
      }}
    >
      <div
        style={{
          backgroundColor: file.status === 'loading' ? 'rgba(var(--containerColor-rgb), 0.6)' : undefined,
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}

      >
        {file.status === 'loading'  && <Loader diam={40} />}
      </div>
    </div>
    <div className='column align-center justify-space-between'>
      <IconWithHover disabled={file.status === 'loading'} iconClass='fa-solid fa-trash color-red' onClick={()=>deleteImageHandler(file.imageUrl)} />    
      { index === 0 && <i  className='fa-solid fa-star' style={{color: colors['primaryColor']}} />}
    </div>  
  </div>
)}

const uploadImage =async(file, setDisableSave)=>{
  try{
    const image = file;
    const formData = new FormData();
    formData.append('image', image);
    formData.append('product_id', window.productId);
    formData.append('store_id', localStorage.getItem('storeId'));
    setDisableSave(true)
    const response = await axiosTr({
        method: 'POST',
        url: filesUrl + '/upload-gallery-image', // Replace with your actual upload URL
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data: formData
    })
    setDisableSave(false)
    return response.data.url
  }
  catch{
    return null
  }
}

const UploadImagesSection = forwardRef(({show}, ref) => {
  const defaultGalleryImages = useContextSelector(AddProductContext, state=>state.productInfo.galleryImages) 
  const setDisableSave = useContextSelector(AddProductContext, state=>state.setDisableSave) 
  const {setGlobalMessageA} = useBrowserContext()

  const [files64Base ,setFiles64Base] = useState(defaultGalleryImages)
  const files64BaseRef = useRef(files64Base)
  files64BaseRef.current = useMemo(()=>files64Base, [files64Base])
  const [previousOrdering, setPreviousOrdering] = useState(files64Base) // in case if canceling the ordering

  const inputRef = useRef()
  const [disablePinning, setDisablePinning] = useState(false)
  const [optimizingImages, setOptimizingImages] = useState(false)
  const [saveOrderingActive, setSaveOrderingActive] = useState(false)
  const changeHandler=async (e)=>{
    setDisablePinning(true)
    setTimeout(()=>{
      if(inputRef.current) inputRef.current.value = ''
    }, 200)

    setOptimizingImages(true)
    setDisableSave(true)
    const newFiles = await reduceImageQuality(e.target.files, 0.7, 2048, "webp")
    setOptimizingImages(false)
    setDisableSave(false)
    let newFiles64Base= []
    
    for (let newFile of newFiles){
      const order = files64Base.length + newFiles64Base.length + 1
      const base64Url = await fileToBase64(newFile)
      const newFile64Base = {
        base64Url : base64Url,
        order: order,
        file : newFile,
        status: 'loading'
      }
      newFiles64Base=[
        ...newFiles64Base,
        newFile64Base,

      ]
    }
    setFiles64Base(files=>([
      ...files,
      ...newFiles64Base,
    ]))
    const newImagesUrl = []
    for (const file64base of newFiles64Base){
        const imageUrl = await uploadImage(file64base.file, setDisableSave)
        if (imageUrl){
          setFiles64Base((oldFiles)=>{
            const newFiles=  [...oldFiles]
            const file = newFiles.find(file=>file.order === file64base.order)
            file.status = 'success'
            file.imageUrl = imageUrl
            return newFiles
          })
          newImagesUrl.push(imageUrl)
        }
        else{
          setGlobalMessageA({
            color: 'red',
            children: translate('Your image was not added'),
            time: 2000
          })
          setFiles64Base(files=>{
            const newFiles = files.filter(file=>file.order !== file64base.order)
            return newFiles.filter(file=>file.order !== file64base.order)
          })
        }
    }
    setDisablePinning(false)
  }

  const deleteImageHandler=async(url)=>{
    setDisableSave(true)
    setFiles64Base(files=>{
      const newFiles=  [...files]
      const file = newFiles.find(file=>file.imageUrl === url)
      file.status = 'deleting'
      return newFiles
    })

    const response =await deleteImage(url, 'product/gallery-image')
    if (!response){
      setFiles64Base(files=>{
        const newFiles=  [...files]
        const file = newFiles.find(file=>file.imageUrl === url)
        file.status = 'success'
        return newFiles
      })
      return
    } 
    setFiles64Base(files=>{
      const newState = files.filter(file=>file.imageUrl !== url)
      return newState
    })
    setDisableSave(false)
  }

  const [deletingAll, setDeletingAll] = useState(false)
  const deleteAllImages =async()=>{
    deleteAllModal.current?.close()
    setDeletingAll(true)
    setDisableSave(true)
    try{
      const response = await axiosTr.post(
          filesUrl + '/delete-all-gallery-images',
          {
              product_id: window.productId,
          },
          {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json'
              }
          }
      )
      const deletedImages = response.data.deletedImages
      setFiles64Base(files=>{
        const newFiles = files.filter(file=>!deletedImages.includes(file.imageUrl))
        return newFiles
      })
    }catch{
      setGlobalMessageA({
        color: 'red',
        children: translate('All images were not deleted'),
        time: 2000
      })
    }
    setDeletingAll(false)
    setDisableSave(false)
  }
  const deleteAllModal = useRef()

  useImperativeHandle(ref, ()=>({
    galleryImages: files64Base.length > 0 ? files64Base.map(file=>file.imageUrl) : undefined
  }))

  const firstCycleDone = useRef(false)
  useEffect(()=>{
    if (!firstCycleDone.current){
      firstCycleDone.current = true
      return
    }
    if(JSON.stringify(files64Base) !== JSON.stringify(previousOrdering)){
      setSaveOrderingActive(true)
    }else{
      setSaveOrderingActive(false)
    }
  }, [files64Base])

  return <div className={'container column p-2 m-3 g-4'}>
    <div>
      <h3 className='color-primary mt-2 d-f g-3 align-items-center'>
        {translate('Images')}
        { files64Base.length > 0 && optimizingImages && <Loader diam={28} />}
      </h3>
    </div>
    {
     show && (
        <div className={'column'} id='upload-images-section'>
            <div disabled={deletingAll}>
              <input
                  type='file'
                  accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, image/tiff"
                  multiple={true}
                  onChange={changeHandler}
                  style={{
                    display: 'none'
                  }}
                  ref={inputRef}
                />
              <div className={'container p-3 ' + classes['gallery-container']} style={{height: '50vh', overflowY:'auto', borderRadius: 0}}>
                { files64Base.length === 0 &&

                      <div style={{width: '100%', height: '100%'}} className='d-f align-items-center justify-center'>
                        { !optimizingImages && <MotionItem><IconWithHover iconClass='fa-solid fa-cloud-arrow-up color-primary' size={100} onClick={()=>inputRef.current?.click()} /></MotionItem>}
                        { optimizingImages && <MotionItem className='jiggle color-primary'><Loader diam={100} /></MotionItem>}
                      </div>
                  }
                  {
                    files64Base.length > 0 &&
                      <>
                        <div className='d-f g-2 mb-3'>
                          <MotionItem className='flex-1'>
                            <Button onClick={()=>inputRef.current?.click()} outline style={{width: '100%', gap: 8}}>
                              <i className='fa-solid fa-square-plus' style={{fontSize: 20}}></i>
                              {translate('Add Images')}
                            </Button>
                          </MotionItem>
                          { files64Base.length > 1 && <MotionItem disabled={disablePinning} style={{width: '48%'}}>
                            <Button theme='red' onClick={()=>deleteAllModal.current?.toggle()} style={{width: '100%', gap: 8}}>
                              <i className='fa-solid fa-trash' style={{fontSize: 16}}></i>
                              {translate('Delete all')}
                            </Button>
                          </MotionItem>}
                        </div>
                        <div className='reorder-item' style={{width: '100%'}}> 
                          <Drag2D 
                            setItems={setFiles64Base} 
                            items={files64Base}
                            ImageCard={ImageCard}
                            cardProps={{deleteImageHandler}}
                            keyExtractor={item=>item.order}
                          />
                        </div>
                      </>
                  }
              </div>
              { files64Base.length > 1 && 
                <div className='d-f justify-space-around g-2 mt-2'>
                  <DialogComponent ref={deleteAllModal} backDropPressCloses>
                    <div style={{
                        width: '80vw',
                        maxWidth: 600,
                        mxHeight: '50vh',
                        backgroundColor: 'var(--containerColor)',
                        borderRadius: 8,
                        padding: 10,
                        gap: 16,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <h4>{ translate('Are you sure you want to delete all images ?') }</h4>
                        <div className='d-f justify-space-between'>
                          <Button theme='red' outline onClick={deleteAllImages} >Delete all</Button>
                          <Button theme='dark' onClick={()=>deleteAllModal.current?.close()}>Cancel</Button>
                        </div>
                    </div>
                  </DialogComponent>
                </div>
              }
            </div>
        </div>
      ) 
    }
  </div>
      
})

export default UploadImagesSection