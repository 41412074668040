import { apiUrl, filesUrl } from 'constants/urls'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import {createContext, useContextSelector} from 'use-context-selector'
import classes from '../../Orders.module.css'
import Input from 'components/tags/Input'
import { axiosTr, downloadCsv, formatDate, getPageList, objectToCsv, slugify, translate } from 'utils/utils'
import { TableHead } from 'pages/Orders'
import Button from 'components/Button'
import { useBrowserContext } from 'store/browser-context'
import CustomCheckbox from 'components/CustomCheckBox'
import Select from 'components/tags/Select'
import DialogComponent from 'components/tags/Dialog'
import OptionsContainer from 'components/OptionsContainer'
import IconWithHover from 'components/IconWithHover'
import Loader from 'components/Loader'
import AddOrder from '../components/AddOrder'
import Img from 'components/Img'
import facebookImage from '../../../assets/icons/apps/facebook.png'
import tiktokImage from '../../../assets/icons/apps/tiktok.png'


const adImages = {
    facebook: facebookImage,
    tiktok: tiktokImage
}

const SearchBox=()=>{
    const searchText = useContextSelector(OrdersContext, state=>state.searchText)
    const defaultSearchText = useRef(searchText)
    const setSearchText = useContextSelector(OrdersContext, state=>state.setSearchText)
    const getOrders = useContextSelector(OrdersContext, state=>state.getOrders)
    const disabled = searchText.trim() == defaultSearchText.current.trim()
    const clickHandler=async(e)=>{
        e.preventDefault()
        const saveValue = defaultSearchText.current
        defaultSearchText.current = searchText
        const reponse = await getOrders()
        if (!reponse){
            defaultSearchText.current = saveValue
        }
    }
    return(
        <form onSubmit={clickHandler} className='d-f align-items-center justify-content-between g-3 w-100'>
            <button>
                <IconWithHover disabled={disabled} iconClass='fa-solid fa-search px-1 color-primary' />
            </button>
            <Input value={searchText} onChange={(value)=>setSearchText(value)} className={classes['search__input']} placeholder={translate('Client full name, phone number, product name or order ID')} />
        </form>
    )
}

const dateList =[
    {
        id:'0',
        label: '',
    },
    {
        id:'1',
        label:  translate('1 day'),
    },
    {
        id:'2',
        label: translate('7 days'),
    },
    {
        id:'3',
        label: translate('1 month'),
    },
    {
        id:'4',
        label: translate('1 year'),
    },

]
const ordersPerPageList =[
    {
        id:'2',
        label: 20,
    },
    {
        id:'3',
        label: 50,
    },
    {
        id:'4',
        label: 100,
    },
]

const Filtration=()=>{
    const ordersParameters = useContextSelector(OrdersContext, state=>state.ordersParameters)
    const setOrdersParameters = useContextSelector(OrdersContext, state=>state.setOrdersParameters)
    const getOrders = useContextSelector(OrdersContext, state=>state.getOrders)

    const ordersPerPage = useRef(ordersParameters.ordersPerPage)
    const selectedDate = useRef(ordersParameters.selectedDate)

    const minDate = useRef(ordersParameters.minDate)
    const maxDate = useRef(ordersParameters.maxDate)
    const activeDateFilter = useRef(ordersParameters.activeDateFilter)

    const updateOrdersParameters=(value, id)=>{
        setOrdersParameters(ordersParameters=>({
            ...ordersParameters,
            [id]: value
        }))
    }
    const updateParamters=async()=>{ 
        await getOrders()
        ordersPerPage.current = ordersParameters.ordersPerPage
        selectedDate.current = ordersParameters.selectedDate 
        minDate.current = ordersParameters.minDate
        maxDate.current = ordersParameters.maxDate
        activeDateFilter.current = ordersParameters.activeDateFilter
    }

    let disabled = (ordersPerPage.current.id == ordersParameters.ordersPerPage.id) && 
    (selectedDate.current.id === ordersParameters.selectedDate.id) &&
    (maxDate.current === ordersParameters.maxDate) &&
    (minDate.current === ordersParameters.minDate) &&
    (activeDateFilter.current === ordersParameters.activeDateFilter)


    const maxDateChangeHanlder=(newDate)=>{
        let newMinDate = ordersParameters.minDate
        const d1 = new Date(newDate);
        const d2 = new Date(newMinDate);
        if (d2 > d1) {
            newMinDate = newDate
        } 
        setOrdersParameters(ordersParameters=>({
            ...ordersParameters,
            minDate: newMinDate,
            maxDate: newDate
        }))
    }
    const minDateChangeHanlder=(newDate)=>{
        setOrdersParameters(ordersParameters=>({
            ...ordersParameters,
            minDate: newDate,
        }))
    }

    const updateActiveDateFiler=(value)=>{
        setOrdersParameters(ordersParameters=>({
            ...ordersParameters,
            activeDateFilter: value,
        }))
    }
    const {langTerms} = useBrowserContext()
    return(
        <div className='container p-2 mb-3 column g-3'>
            <table className={classes['filtration-table']}>
                <tbody>
                    <tr>
                        <td>
                            <h4>{ translate('Orders per page:') }</h4>
                        </td>
                        <td/>
                        <td>
                            <div>
                                <Select options={ordersPerPageList} selectedOption={ordersParameters.ordersPerPage} onChange={(option)=>updateOrdersParameters(option, 'ordersPerPage')} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <hr className='my-2'/>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <h4>{ translate('Date') }:</h4>
                        </td> 
                        <td/> 
                        <td className='d-flex align-items-center g-3'>
                            <div style={{flex: 1}} disabled={ordersParameters.activeDateFilter === 2}>
                                <Select options={dateList} selectedOption={ordersParameters.selectedDate} onChange={(newDate)=>updateOrdersParameters(newDate, 'selectedDate')} />
                            </div>
                            <CustomCheckbox checked={ordersParameters.activeDateFilter === 1} onChange={()=>updateActiveDateFiler(1)} />
                        </td>    
                    </tr>
                    <tr>
                        <td >
                            <input type='date' disabled={ordersParameters.activeDateFilter === 1} max={ordersParameters.maxDate} value={ordersParameters.minDate} onChange={e=>minDateChangeHanlder(e.target.value)} className='box-input'  />
                        </td>
                        <td>
                            <i disabled={ordersParameters.activeDateFilter === 1} className={`fa-solid fa-arrow-${langTerms['right']}-long`}/>
                        </td>
                        <td>
                            <div className='d-flex align-items-center g-3'>
                                <input type='date' disabled={ordersParameters.activeDateFilter === 1} max={today} value={ordersParameters.maxDate} onChange={e=>maxDateChangeHanlder(e.target.value)} className='box-input' />
                                <CustomCheckbox checked={ ordersParameters.activeDateFilter === 2} onChange={()=>updateActiveDateFiler(2)} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <hr className='mt-2'/>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Button disabled={disabled} onClick={updateParamters} className='mt-2'>{ translate('Update paramters') }</Button>
        </div>
    )
}

const OrdersContext = createContext({
    renderedOrders: [], 
    setRenderedOrders: ()=>{},
    statusList: [],
    blockedVisitors: [], 
    setBlockedVisitors: ()=>{},
    getOrders: async()=>{},
    deleteOrders : async()=>{},
    selectedOrders: [],
    toggleOrder: (orderId)=>{},
    deletingSelectedOrders: false,
    selectedStatus: [], 
    setSelectedStatuses: ()=>{}
})

const today = new Date().toISOString().split('T')[0]
const defaultOrdersParameters = {
    ordersPerPage: ordersPerPageList[0],
    selectedDate:  dateList[0],
    minDate: today,
    maxDate: today,
    activeDateFilter: 1

}

const defaultOrdersOptions=[
    {
        id: 1,
        label: translate('Select'),
        disabled: true
    },
    {
        id: 2,
        label: translate('Delete selected')
    },
    {
        id: 3,
        label: translate('Update status')
    },
    {
        id: 4,
        label: translate('Download as CSV')
    },
]

const StatusFilter=()=>{
    const statusList = useContextSelector(OrdersContext, state=>state.statusList)
    const setSelectedStatuses = useContextSelector(OrdersContext, state=>state.setSelectedStatuses)
    const selectedStatus = useContextSelector(OrdersContext, state=>state.selectedStatus)

    const adCompanies = useContextSelector(OrdersContext, state=>state.adCompanies)
    const selectedAdCompanies = useContextSelector(OrdersContext, state=>state.selectedAdCompanies)
    const setSelectedAdCompanies = useContextSelector(OrdersContext, state=>state.setSelectedAdCompanies)

    const allOrdersCount = useContextSelector(OrdersContext, state=>state.allOrdersCount)

    const toggleStatus = (statusId)=>{
        setSelectedStatuses(selectedStatus=>{
            const newState = [...selectedStatus]
            const currentStatus = newState.find(elem=>elem ===statusId) 
            if (currentStatus) return newState.filter(elem=>elem !== statusId)
            else return [...newState, statusId]
        })
    }
    
    const toggleAdCompany = (companyId)=>{
        setSelectedAdCompanies(selectedCompanies=>{
            const newState = [...selectedCompanies]
            const currentCompany = newState.find(elem=>elem ===companyId) 
            if (currentCompany) return newState.filter(elem=>elem !== companyId)
            else return [...newState, companyId]
        })
    }
    
    return(
        <>
            <div className='d-flex g-3 flex-wrap'>
               { allOrdersCount && <div style={{
                        borderRadius: 8,
                        display: 'flex',
                        padding: 4,
                    alignItems: 'center',
                    gap: 4
                }} 
                className='border'
                >
                    <p>{ translate('All') }</p>
                    <h4 >{allOrdersCount}</h4>

                </div>}
                { statusList?.filter(status=>status.count > 0).map((status, index)=>(
                    <div style={{
                        backgroundColor: selectedStatus.includes(status.id) ? 'rgba(var(--primaryColor-rgb), 0.4)' : undefined,
                        borderRadius: 8,
                        display: 'flex',
                        padding: 4,
                        alignItems: 'center',
                        cursor: 'pointer',
                        gap: 4
                    }} 
                    onClick={()=>toggleStatus(status.id)}
                    className='border'
                    key={status.id}
                    >
                        <Img width={22} height={22} src={`${filesUrl}/` + status.icon}/>
                        <h4 >{status.count}</h4>

                    </div>
                ))}
            </div>
            <hr className='my-2'/>
            <div className='d-flex g-3 flex-wrap'>
                {
                    adCompanies.map(company=>{
                        return(<div style={{
                            backgroundColor: selectedAdCompanies.includes(company.id) ? 'rgba(var(--primaryColor-rgb), 0.4)' : undefined,
                            borderRadius: 8,
                            display: 'flex',
                            padding: 4,
                            alignItems: 'center',
                            cursor: 'pointer',
                            gap: 4,
                        }} 
                        onClick={()=>toggleAdCompany(company.id)}
                        className='border d-flex g-2 px-2'
                        key={company.id}
                        >
                            {
                                adImages[company.id] ? <Fragment key={company.id}><img src={adImages[company.id]} width={30} /><h4 >{company.count}</h4></Fragment> :
                                <Fragment key={company.id}><p>{ company.label }</p><h4 >{company.count}</h4></Fragment>
                            }
                        </div>)
                    })
                }
            </div>
        </>
    )
}

const OrdersContextProvider = ({children, abandoned})=>{
    useEffect(()=>{
        document.documentElement.style.overflowY = 'scroll'
        return ()=>{
            document.documentElement.style.removeProperty('overflow-y')
        }
    }, [])
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])
    const [renderedOrders, setRenderedOrders] = useState([])
    const [statusList, setStatusList] = useState(null)
    const [selectedStatus, setSelectedStatuses] = useState([]) 
    const [shippingAccounts, setShippingAccounts] = useState([])

    useEffect(()=>{
        setRenderedOrders(orders.map(order => ({
            ...order,
            detailsShown: false,
            showStatusList: false
        })))
    }, [orders])
    
    
    const [error, setError] = useState(false)    
    const [page, setPage] = useState(1)
    const [ordersParameters, setOrdersParameters] = useState(defaultOrdersParameters)
    const [searchText, setSearchText] = useState('')
    const [adCompanies, setAdCompanies] = useState([])
    const [selectedAdCompanies, setSelectedAdCompanies] = useState([]) 

    const [allOrdersCount, setAllOrdersCount]  =useState(null)
    const first_fetch = useRef(true)
    const getOrders=async()=>{
        setShowFiltration(false)
        setLoading(true)
        setError(false)
        let linkExtention = ''
        linkExtention += '&orders_per_page=' + ordersParameters.ordersPerPage.label
        const searchTextTrimmed = searchText.trim()
        if(searchTextTrimmed) linkExtention+= '&search_text=' + searchTextTrimmed
        if(ordersParameters.activeDateFilter === 1){
            if (ordersParameters.selectedDate.label) linkExtention += '&date=' + slugify(ordersParameters.selectedDate.label)
        }
        else {
            linkExtention += `&min_date=${ordersParameters.minDate}&max_date=${ordersParameters.maxDate}`
        }
       
        try{
            const {data} = await axiosTr.get(apiUrl + `/orders/${ abandoned ? 'get-abandoned-orders' : 'get-orders' }?page=${ page }&store_id=${localStorage.getItem('storeId')}${linkExtention}&status_list=${JSON.stringify(selectedStatus)}&ad_companies_list=${JSON.stringify(selectedAdCompanies)}${ first_fetch.current ? '&first_fetch=true': '' }`,  
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    // 'Accept-Language': 'ar',
                }
            })
            first_fetch.current = false
            setOrders(data['orders'])
            setNumPages(data.numPages)
            setHasNext(data.hasNext)
            setHasPrev(data.hasPrev)
            setPage(Number(data.current_page))
            setStatusList(data.statusList)
            if(data.shipping_accounts) setShippingAccounts(data.shipping_accounts)

            setAdCompanies(data.ad_companies_list.filter(company=>company.count > 0))
            setSelectedOrders([])
            setAllOrdersCount(data.all_orders_count)
            setLoading(false)
            return true
        }catch{
            setError(true)
            setLoading(false)
            return false
        }   
    }

    useEffect(()=>{
        getOrders()
    },[page, selectedStatus, selectedAdCompanies])

    const [numPages, setNumPages]= useState([])
    const [hasPrev, setHasPrev]=useState(false)
    const [hasNext, setHasNext]=useState(false)

    const [blockedVisitors, setBlockedVisitors] = useState([])

    const {setGlobalMessageA} = useBrowserContext()

    const deleteOrders = async(list, setLoading)=>{
        setLoading(true)
        try{
            const {data} = await axiosTr.post(
                apiUrl + '/orders/delete-orders',
                {
                    store_id : localStorage.getItem('storeId'),
                    orders_ids: list
                },
                {
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }
            )
            const deletedOrdersId = data.ordersId
            setRenderedOrders(orders=>orders.filter(order=>!deletedOrdersId.includes(order.id)))
            setLoading(false)
            return true
        }catch(err){
            console.log(err)
            setLoading(false)
            return false
        }   
    }

    const changeOrderStatusBluk=async(status, ordersList, setLoadingStatus)=>{
        setLoadingStatus(true)
        try{
            await axiosTr.post(
                apiUrl + '/orders/change-orders-status',
                {
                    orders_id: ordersList,
                    status_id: status.id,
                    store_id: localStorage.getItem('storeId')
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }
            )
            setLoadingStatus(false)
            return true
        }catch{
            setLoadingStatus(false)
            return false
        }
    }

    const [selectedOrders, setSelectedOrders] = useState([])
    const toggleOrder=(orderId)=>{
        if(selectedOrders.includes(orderId)){
            setSelectedOrders(selectedOrders=>selectedOrders.filter(elem=> elem !== orderId))
        }else{
           setSelectedOrders(selectedOrders=>[...selectedOrders, orderId]) 
        }     
    }

    const allOrders = useMemo(()=>renderedOrders.map(order=>order.id), [renderedOrders])
    const ordersOptions = useMemo(()=>{
        const shippingPart = shippingAccounts.map(account=>({
            label: translate('Ship with {account}', {account: account.name}),
            id: 's-' + account.id
        }))
        return [
            ...defaultOrdersOptions,
            ...shippingPart
        ]
    }, [shippingAccounts])
    const toggleSelectAll=()=>{
        if (selectedOrders.length === allOrders.length){
            setSelectedOrders([])
            setSelectedOption(ordersOptions[0])
        }
        else{
            setSelectedOrders(allOrders)
        }
            
    }
    const [selectedOption, setSelectedOption] = useState(ordersOptions[0])
    const orderSelectChangeHandler=async(op)=>{
        setSelectedOption(op)
        if (op.id === 2){
            deleteDalogRef.current.open()
        }
        else if (op.id === 3){
            updateBulkStatusDalogRef.current.open()
        }
        else if (op.id === 4){
            const serializedData = renderedOrders.filter(order=>selectedOrders.includes(order.id)).map(order=>{
                let variants = ''
                if(order.product.combination){
                    Object.entries(order.product.combination).forEach(([key, value], index)=>{
                        variants+=`${key}: ${value}; `
                    })
                }
                variants = variants || '/'
                const shippingCost = order.product.shipping_cost || 0
                return{
                    [translate('ID')]: order.id,
                    [translate('Client name')]: order.full_name,
                    [translate('Product title')]: order.product.title,
                    [translate('Ordering date')]: formatDate(order.created_at),
                    [translate('Phone number')]: order.phone_number,
                    [translate('Shipping to')]: order.shipping_to_home ? translate('Home') : translate('Office'),
                    [translate('Shipping state')]: order.shippingState,
                    [translate('Shipping city')]: order.shipping_to_home ? order.shippingCity : '/',
                    [translate('Status')]: order.status.text,
                    [translate('Quantity')]: order.product_quantity,
                    [translate('Total price')]: order.product.price * order.product_quantity + Number(shippingCost),
                    [translate('Variants')]: variants
                }})
            const csvData = objectToCsv(serializedData)
            downloadCsv(csvData, 'orders.csv')
        }
        else if (op.id.startsWith('s')){
            const account = shippingAccounts.find(account=>account.id == op.id.split('-')[1])
            if (account.company_name === 'ProColis') {
                setLoading(true)
                try{
                    const response = await axiosTr.post(
                        apiUrl + '/store/ship-to-procolis',
                        {
                            store_id : localStorage.getItem('storeId'),
                            orders_id: selectedOrders,
                            account_id : account.id
                        },
                        {
                            headers: {
                                'Content-type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                            }
                        }
                    )
                    const Colis = response.data.Colis
                    if (Colis.length > 0){
                        setRenderedOrders(orders=>{
                            const newState = [...orders]
                            const status = statusList.find(state=>state.order === 3)
                            return newState.map(order=>{
                                if (Colis.includes(String(order.id))){
                                    return{
                                        ...order,
                                        status: {
                                            text: status.text,
                                            icon: status.icon,
                                            id: status.id
                                        }
                                    }
                                }else{
                                    return order
                                }
                                
                            })
                        })
                    }
                    setGlobalMessageA({
                        children: response.data.detail,
                        color: 'var(--successColor)',
                        time: 3000
                    })
                }   
                catch (err){
                    setGlobalMessageA({
                        children: err.response?.data.detail || err.messgae,
                        color: 'red',
                        time: 3000
                    })
                }
                setLoading(false)
            }
        }
        
        setSelectedOption(ordersOptions[0])
    }
    const deleteDalogRef = useRef()
    const [deletingSelectedOrders, setDeletingSelectedOrders] = useState(false)
    const deleteSelectedOrders=async()=>{
        deleteDalogRef.current.close()
        const response = await deleteOrders(selectedOrders, setDeletingSelectedOrders)
        if (!response){
            setGlobalMessageA({
                children: translate('The orders you selected were not deleted'),
                color: 'red',
                time: 3000
            })
            return
        }
        setSelectedOrders([])
    }
    const updateBulkStatusDalogRef = useRef()
    const [upadtingBlukStatus, setUpdatingBlukStatus] = useState(false)
    const updateStatusSelectedOrders=async(status)=>{
        updateBulkStatusDalogRef.current.close()
        const response = await changeOrderStatusBluk(status, selectedOrders, setUpdatingBlukStatus)
        if (!response){
            setGlobalMessageA({
                children: translate('Error while updating statuses'),
                color: 'red',
                time: 3000
            })
            return
        }
        setRenderedOrders(renderedOrders=>renderedOrders.map(order=>{
            if(selectedOrders.includes(order.id)){
                return({
                    ...order,
                    status
                })
            }
            else {
                return order
            }
        }))
        setSelectedOrders([])
    }
    const [show, setShow] = useState(false)
    const [showFilteration, setShowFiltration] = useState(false)
    const defaultValue={
        renderedOrders, setRenderedOrders,
        statusList,
        blockedVisitors, setBlockedVisitors,
        getOrders,
        deleteOrders,
        selectedOrders,
        toggleOrder,
        deletingSelectedOrders: deletingSelectedOrders,
        changeOrderStatusBluk,
        ordersParameters, setOrdersParameters,
        searchText, setSearchText,
        selectedStatus, 
        setSelectedStatuses,
        adCompanies,
        selectedAdCompanies, 
        setSelectedAdCompanies,
        allOrdersCount,
        shippingAccounts,
    }
    return(
        <OrdersContext.Provider value={defaultValue}>
            <div className='p-2 flex-1 orders-table'>
                <div className={classes['orders__container']}>
                    <div className={classes['search__container']}>
                        <div className='d-f align-items-center justify-content-between g-3'>
                            <SearchBox/>
                            <IconWithHover iconClass='fa-solid fa-filter px-1 color-primary' onClick={()=>setShowFiltration(!showFilteration)} />
                        </div>
                    </div>
                    <DialogComponent open={showFilteration} close={()=>setShowFiltration(false)} >
                        <Filtration />
                    </DialogComponent>
                    <div className='py-2'>
                        <div className='w-100 d-f g-3'>
                            <Select  disabled={selectedOrders.length === 0} options={ordersOptions} selectedOption={selectedOption} onChange={orderSelectChangeHandler} containerStyle={{maxWidth: 380}} />
                            { !abandoned && <Button style={{whiteSpace: 'nowrap'}} className='d-f g-3 px-2' onClick={()=>setShow(true)}>
                                <i className='fa-solid fa-plus-square align-items-center' style={{fontSize: 24}} />
                                { translate('Add order') }
                            </Button>}
                            { show && 
                            <DialogComponent open={show} close={()=>setShow(false)} backDropPressCloses={false}>
                                <AddOrder/>
                            </DialogComponent>
                            }
                        </div>
                        <div className='mt-2'>
                            <StatusFilter/>
                        </div>
                        <DialogComponent
                            ref={deleteDalogRef}
                        >
                            <div className='container p-2 column g-4' style={{maxWidth: '80vw'}}>
                                <h4 style={{textAlign: 'start'}}>{translate('Are you sure you want to delete this option?')}</h4>
                                <div className='d-f justify-space-between'>
                                <Button outline onClick={deleteSelectedOrders}>Yes</Button>
                                <Button theme='dark' onClick={()=>deleteDalogRef.current.close()}>No</Button>
                                </div>
                            </div>
                        </DialogComponent>
                        <DialogComponent
                            ref={updateBulkStatusDalogRef}
                        >
                            <div style={{maxWidth: '80vw'}}>
                                <OptionsContainer scroll={false} position='relative' className='mt-2' show setShow={()=>{}} > 
                                    {
                                        statusList?.map(status=>(
                                            <div disabled={upadtingBlukStatus} className={classes['status-list-container'] + ' d-f p-2 g-3'} key={status.id} onClick={()=>updateStatusSelectedOrders(status)}>
                                                <img width={30} src={filesUrl + status.icon} />
                                                <span>
                                                    { status.text }
                                                </span>
                                            </div>
                                        ))
                                    }
                                </OptionsContainer>
                            </div>
                        </DialogComponent>
                    </div>
                    <div className={"table-flex container p-relative"}>
                        <div>
                            <div className='table-row header'>
                                <div className='d-f table-cell'>
                                    { allOrders.length > 0 && <CustomCheckbox scale='0.8' onChange={toggleSelectAll} checked={selectedOrders.length === allOrders.length} />}
                                </div>
                                {<TableHead />}
                            </div>        
                        </div>
                        <div className={loading ? 'blur': undefined } style={{minHeight: 400}}>
                            {children}        
                        </div>  
                        {loading && 
                            <div style={{height: '100%', width: '100%', position: 'absolute', top:0, left:0}} className='d-f align-items-center justify-content-center'>
                                    <Loader diam={200}  />
                            </div>
                        }
                    </div>
                    { (hasNext || hasPrev) && 
                        <div disabled={loading} className='p-2 d-f g-2 justify-center flex-wrap' style={{alignItems: 'start'}}>                            
                            {
                                getPageList(page, numPages)
                                .map(elem=> elem === '...' ? 
                                    <h4 key={elem}>.....</h4> : 
                                    <Button key={elem} outline={elem === page} onClick={()=>{setPage(elem)}}>
                                        {elem}
                                    </Button>
                                )
                            }

                        </div>
                    }
                </div>
            </div>
        </OrdersContext.Provider>
    )
}

export default OrdersContextProvider
export {OrdersContext}