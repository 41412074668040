import Button from 'components/Button'
import IconWithHover from 'components/IconWithHover'
import Input from 'components/tags/Input'
import { apiUrl } from 'constants/urls'
import React, { useEffect, useRef, useState } from 'react'
import { useBrowserContext } from 'store/browser-context'
import { axiosTr, translate } from 'utils/utils'

const AccountComponent = ({account, updateAccount, deleteAccount, index})=>{

    const isNewAccount = account.id === -1
    const name = useRef(account.name)
    const token = useRef(account.extra_data.token)
    const key = useRef(account.extra_data.key)

    const setName = (value)=>{
        updateAccount({
            ...account,
            name: value
        })
    }
    const setId = (value)=>{
        updateAccount({
            ...account,
            id: value
        }) 
    }
    const setExtraData = (id, value)=>{
        updateAccount({
            ...account,
            extra_data: {
                ...account.extra_data,
                [id]: value
            }
        })
    }

    const {setGlobalMessageA} = useBrowserContext()
    const [loading, setLoading] = useState(false)
    const update=()=>{
        setLoading(true)
        axiosTr.post(
            apiUrl + (isNewAccount ? '/store/add-procolis-to-store' : '/store/update-procolis-data'),
            {
                name: account.name,
                token: account.extra_data.token,
                key: account.extra_data.key,
                store_id: localStorage.getItem('storeId'),
                id: account.id
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(response=>{
            name.current = account.name
            token.current = account.extra_data.token
            key.current = account.extra_data.key
            if(isNewAccount) setId(response.data.id)

            setGlobalMessageA({
                children: response.data.detail,
                color: 'var(--successColor)',
                time: 3000
            })
            setLoading(false)
        }).catch(error=>{
            setGlobalMessageA({
                children: error.response?.data.detail || error.message,
                color: 'red',
                time: 3000
            })
            setLoading(false)
        })
    }

    const [deleting, setDeleting] = useState(false)
        
    const deleteAccountInner=async()=>{
     setDeleting(true)
     await deleteAccount(!isNewAccount)
     setDeleting(false)   
    }
    const disabled = loading || !account.extra_data.token || !account.extra_data.key || ((name.current === account.name) && (token.current === account.extra_data.token) && (key.current ===  account.extra_data.key))
    return(
        <div className='d-flex flex-column g-3 container p-2' disabled={deleting || loading}>
            <div className='d-flex justify-content-between align-items-center'>
                <h3>{ translate('Account {num}', {num: index + 1}) }</h3>
                <IconWithHover iconClass='fa-solid fa-trash color-red' onClick={deleteAccountInner}/>
            </div>
            <Input label={translate('Name')} maxLength={20} value={account.name} onChange={value=>setName(value)} />
            <Input label={translate('Token')} value={account.extra_data.token} onChange={value=>setExtraData('token', value)} />
            <Input label={translate('Key')} value={account.extra_data.key} onChange={value=>setExtraData('key', value)} />
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <Button onClick={update} disabled={disabled}>{ isNewAccount ? translate('Add') : translate('Update') }</Button>
            </div>
        </div>
    )
}

const Procolis = () => {
    const [accounts, setAccounts] = useState([])
    useEffect(()=>{
        axiosTr.get(
            apiUrl + '/store/get-procolis-accounts?store_id=' + localStorage.getItem('storeId'),
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(response=>{
            setAccounts(response.data)
        })
    }, [])

    const updateAccount = (accountId, newAccount)=>{
        setAccounts(accounts=>{
            const newAccounts = [...accounts]
            const currentAccount = newAccounts.find(elem=>elem.id === accountId)
            Object.assign(currentAccount, newAccount)
            return newAccounts
        })
    }
    const deleteAccount=async(id, fetch=true)=>{
        try{
           if(fetch) {
                await axiosTr.post(
                    apiUrl + '/store/remove-procolis-account',
                    {
                        store_id: localStorage.getItem('storeId'),
                        id: id
                    },
                    {
                        headers: {
                            'Content-Type': 'json/application',
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    }
                )
            }
            setAccounts(accounts=>accounts.filter(acc=>acc.id !== id))
            return true
        }catch{
            return false
        }
        
    }
    const addAccount = ()=>{
        setAccounts([ 
            ...accounts,
            {
                name: '',
                extra_data: {
                    token: '',
                    key: ''
                },
                id: -1
            }
        ])
    }

    return (
        <div className='d-flex flex-1'>
            <div className='flex-1 d-flex flex-column p-2' style={{maxWidth: 1920, margin: '0 auto'}}>
                <div className='container p-2'>
                    <h2 className='color-primary'>{ translate('ProColis') }</h2>
                    <p>{translate('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pellentesque non eros ac pulvinar. Nullam porttitor justo non eros auctor, nec auctor massa scelerisque. Integer viverra neque odio, sit amet aliquam est luctus sed. Aenean lacinia libero sem, et maximus est pharetra id. Quisque condimentum tempus urna, quis mattis ante dapibus ut. Nullam viverra scelerisque tortor at rhoncus. Etiam quis mollis libero. Fusce sed vestibulum neque.')}</p>
                </div>
                <div className='mt-3 d-flex flex-column g-3'>
                    {
                        accounts.map((account, index)=><AccountComponent deleteAccount={deleteAccount.bind(this, account.id)} updateAccount={updateAccount.bind(this, account.id)} key={account.id} account={account} index={index} />)
                    }
                    <Button className='g-3' background disabled={accounts[accounts.length - 1]?.id === -1} onClick={addAccount}>
                        <i className='fa-solid fa-plus-square' style={{fontSize: 24}} />
                        {translate('Add')}
                    </Button>
                </div>
                
            </div>
        </div> 
    )
}

export default Procolis