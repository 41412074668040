import React, { useState } from 'react'
import { useCustomizeHomePageContext } from '../../../store/CustomizeHomePageContext'
import { translate } from 'utils/utils'
import DialogComponent from 'components/tags/Dialog'
import ColorPicker from 'components/ColorPicker'
import browserData from '../../../../../../../json/browser-data.json'


const EditGeneralDesign = () => {
    const {generalDesign, updateGeneralDesign, visionMode }= useCustomizeHomePageContext()
    const backgroundColorObject = generalDesign.backgroundColor
    const backgroundColor = backgroundColorObject[visionMode]

    const [showColorPicker, setShowColorPicker] = useState(false)

    const updateBackgoundColor=(newBackgroundColor)=>{
      updateGeneralDesign({
        backgroundColor: {
            ...backgroundColorObject,
            [visionMode]: newBackgroundColor
        }
      })
    }
   
  const  bgTransparent = backgroundColor === "#00000000"

  const colors = browserData['browserData']['colors'][visionMode]

  const onChnage = (e)=>{
    const checked = e.target.checked
    if(checked){
      updateBackgoundColor('#00000000')
    } 
    else{
      updateBackgoundColor(colors.backgroundColor)
    }

  }
    return(
      <div className='p-2'>
        <div className='d-f align-items-center g-3 mb-3'>
          <input type='checkbox' checked={bgTransparent} onChange={onChnage} style={{zoom: '0.8'}} />
            <h4>{ translate('Transparent background') }</h4>
        </div>
        { !bgTransparent && <div className='d-f align-items-center g-3'>
          <p>{ translate('Background color') }:</p>
          <h4 className='flex-1'>{ backgroundColor === '#00000000' ? translate('Transparent') : backgroundColor }</h4>
          <div 
            style={{
              border: '1px solid black', 
              padding: 1, 
              width: 54,
              borderRadius: 6
            }} 
            className='cursor-pointer'
            onClick={()=>setShowColorPicker(true)}
          >
            <div style={{
              backgroundColor: backgroundColor,
              width: 50,
              height: 20,
              borderRadius: 4,
            }} />
          </div>
        </div>}
        
        <DialogComponent open={showColorPicker} backDropPressCloses={false} close={ ()=>setShowColorPicker(false) }>
          <ColorPicker defaultColor={{
          label: translate('Primary color'),
          color: window.storeColors.primaryColor
        }} color={backgroundColor} onChange={updateBackgoundColor} />
        </DialogComponent>
      </div>
    )
}

export default EditGeneralDesign