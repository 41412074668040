import React from 'react'
import GallerySwiper from './products-swiper/GallerySwiper'
import { useCustomizeHomePageContext } from '../../../store/CustomizeHomePageContext'
import SimpleSwiper from './products-swiper/SimpleSwiper'

const ProductsSwiper=({section, zoom})=>{
    // products
    const {selectedDevice} = useCustomizeHomePageContext()

    const isSectionProductsContainer = section.type === 'products-container' || section.type === 'category'
    const isSectionSwiper = section.type === 'swiper'

    const selectedSectionDesign = 
        isSectionProductsContainer ? section.design[selectedDevice]:
        isSectionSwiper ? section.design:
        {}

    const displeyType = isSectionProductsContainer ?  selectedSectionDesign.products.productsDisplay : 
                        isSectionSwiper ? selectedSectionDesign.swiperType: undefined

    const swiperLength = isSectionProductsContainer ? section.products.length :
                        isSectionSwiper ? section.imageObjects.length: undefined

  
    return(
        <div>
            { swiperLength > 1 && displeyType !== 'simple' && <GallerySwiper section={section} zoom={zoom} />}
            {( swiperLength === 1 || displeyType === 'simple') && <SimpleSwiper section={section} />}
        </div>
    )
}

export default ProductsSwiper