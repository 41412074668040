import React, { useEffect, useRef, useState } from 'react'
import classes from '../general.module.css'
import Img from 'components/Img'
import metaImage from '../../assets/icons/apps/meta.png'
import { axiosTr, translate } from 'utils/utils'
import Input from 'components/tags/Input'
import Button from 'components/Button'
import { useBrowserContext } from 'store/browser-context'
import { apiUrl } from 'constants/urls'
import IconWithHover from 'components/IconWithHover'

const FacebookSinglePixel = ({pixelData, deletePixel, updatePixels}) => {
  const [tokenActive, setTokenActive] = useState(!!pixelData.apiToken)

  const [pixel, setPixel] = useState(pixelData.fbPixel)

  const [token, setToken] = useState(pixelData.apiToken)

  const [eventTestCode, setTestCode] = useState(pixelData.eventTestCode)

  const [loading, setLoading] = useState(false)

  const [testEventChecked, setTestEventchecked] = useState(!!pixelData.eventTestCode)
  const {setGlobalMessageA} = useBrowserContext()
 
  const setUpConversionApi = async()=>{
    setLoading(true)
    try{
        const {data} = await axiosTr.post(
            apiUrl + (!tokenActive ? '/store/set-up-conversions-api' : '/store/update-conversions-api'), 
            {
                store_id: localStorage.getItem('storeId'),
                old_conversion_api_access_token : pixelData.apiToken,
                conversion_api_access_token: token,
                pixel_id: pixel,
                event_test_code: eventTestCode.trim()
            },
            {
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
        )
        setGlobalMessageA({
            color: 'var(--successColor)',
            children: data.detail,
            time: 3000
        })
        setTokenActive(true)
        updatePixels(pixelData.apiToken, {
            apiToken: token,
            fbPixel: pixel,
            eventTestCode: eventTestCode.trim()
        })
    }catch(err){
        setGlobalMessageA({
            color: 'red',
            children: err.response?.data.detail || translate('Error setting up your conversions api token'),
            time: 3000
        })
    }
    setLoading(false)
        
}

  const [deleting, setDeleting] = useState(false)
  const deleteFBPixel=async()=>{ 
      setDeleting(true)
      try{
          const {data} = await axiosTr.post(
              apiUrl + '/store/delete-conversions-api',
              {
                  store_id:  localStorage.getItem('storeId'),
                  token: pixelData.apiToken,
              },
              {
                  headers:{
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.getItem('token')
                  }
              }

          )
          setPixel('')

          setTokenActive(false)
          setToken('')

          setTestEventchecked(false)
          setTestCode('')
          deletePixel(pixelData.apiToken)
      }catch(err){
          setGlobalMessageA({
              children: translate('Error while removing your facebook pixel, try again'),
              color: 'red',
              time: 4000
          })
      }
      setDeleting(false)
  }

  // Test code

  const onCheck=(checked)=>{
    setTestEventchecked(checked)
    if (checked){
        setTestCode(pixelData.eventTestCode)
    }else{
        setTestCode('')
    }
  }

  const submitActive = (pixel && (pixelData.fbPixel !== pixel)) || ( token && pixelData.apiToken !== token ) || (eventTestCode.trim() && pixelData.eventTestCode !== eventTestCode.trim())

  return (
    <div className={classes['card'] + ' column g-4 '} disabled={deleting}>
        <div className='d-f align-items-center justify-content-between'>
            <span/>
            { tokenActive && <IconWithHover onClick={deleteFBPixel} iconClass='fa-solid fa-trash color-red'/>}
        </div>
        <Input label='Facebook Pixel ID' value={pixel} onChange={setPixel} />            
       <div className='d-flex flex-column g-3'>
            <div className='d-flex g-3 align-items-center'>
                <Input label='Conversions API token' value={token} onChange={setToken} />
            </div>
            <div className='d-flex g-3 align-items-center'>
                <input type='checkbox' checked={testEventChecked} onChange={e=>onCheck(e.target.checked)} /> 
                { !testEventChecked && <h4>{ translate('Test event code') }</h4>}
                {
                    testEventChecked && <>
                        <input value={eventTestCode} onChange={e=>setTestCode(e.target.value)} className='box-input' style={{width: 200}} placeholder={translate('Test event code')} />
                    </>
                }
            </div>
            { (testEventChecked && eventTestCode.trim()) && <h4 className='lh-1' style={{opacity:0.9}}>{ translate('If you are not testing keep this field empty') }</h4>}
        </div>
        { tokenActive && <h4 className='color-red'>{ translate('Do not use both facebook pixel and conversion api on the same pixel this will result in duplicated events which will decrease the accuracy of your pixel.')}</h4> }
        <Button outline disabled={loading || !submitActive} onClick={setUpConversionApi} >{ translate('Set token') }</Button>
    </div>
  )
}

const ConversionApi=()=>{
    const [conversionsApis, setConversionsApis] = useState([])
    const [fetchingData, setFetchingData] = useState(true)
    const [error, setError] = useState(false)
    const getConversionsApis=async()=>{ 
        setFetchingData(true)
        setError(false)
        try{
            const {data} = await axiosTr.get(
                apiUrl + '/store/get-conversion-apis?store_id='+ localStorage.getItem('storeId'),
                {
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }
            )
            if(data.length === 0) setConversionsApis([{
                apiToken : "",
                eventTestCode: "" ,
                fbPixel : "",
            } ])
            else{
                setConversionsApis(data)
            }
                
        }catch(err){
            setError(true)
  
        }
        setFetchingData(false)
            
    }
    useEffect(()=>{
        getConversionsApis()
    }, []) 
    
    const deletePixel=(apiToken)=>{
        setConversionsApis(conversionsApis=>conversionsApis.filter(elem=>elem.apiToken !== apiToken))
    }
    const updatePixels=(apiToken, pixelData)=>{
        setConversionsApis(conversionsApis=>{
            const newList = [...conversionsApis]
            const elem = newList.find(elem=> elem.apiToken === apiToken)
            elem.apiToken = pixelData.apiToken
            elem.fbPixel = pixelData.fbPixel
            elem.eventTestCode = pixelData.eventTestCode
            return newList
        })
    }
    return (
        <div className={classes['container']}> 

            <div className={classes['card'] + ' d-f '}>
                <Img src={metaImage} width={ 100 } style={{ objectFit: 'cover' }}/>
                <div className='flex-1'>
                    <h3 className='color-primary'>{translate('Facebook Conversions API') }</h3>
                    <p className='lh-1'>{translate('Add facebook conversions api to your store to improve our conversion rate.')}</p>
                </div>
            </div>
            {
                conversionsApis && conversionsApis.map(pixel=>(
                    <FacebookSinglePixel 
                        key={pixel.fbPixel} 
                        pixelData={pixel} 
                        deletePixel={deletePixel} 
                        updatePixels={updatePixels}
                    />
                ))
            }
            <div className={classes['card']}>
                <Button 
                    className='col-12 g-3' 
                    onClick={()=>setConversionsApis(conversionsApis=>[...conversionsApis, 
                    {
                        apiToken : "",
                        eventTestCode: "" ,
                        fbPixel : "",
                    }    
                    ])}
                    disabled={(conversionsApis.length > 0 && conversionsApis[conversionsApis.length-1].fbPixel) === ''}
                >
                    <i className='fa-solid fa-plus-square'/>
                    { translate('Add ') }
                </Button>
            </div>
            {
                error && !fetchingData && <div className={classes['card'] + ' column g-4 align-items-center'}>
                <IconWithHover size={80} onClick={getConversionsApis} iconClass='fa-solid fa-rotate-right color-red py-3' />
                </div>
            }
        </div>
                
    )
}

export default ConversionApi