import React, { useEffect, useState } from 'react'
import classes from './CustomizeHomePage.module.css'
import CustomizeHomePageContextProvider, { useCustomizeHomePageContext } from './customize-home-page/store/CustomizeHomePageContext'
import DeviceTypeArea from './customize-home-page/components/DeviceTypeArea'
import EditSection from './customize-home-page/sections/EditSection'
import SectionsSection from './customize-home-page/sections/SectionsSection'
import { axiosTr, translate } from 'utils/utils'
import { apiUrl } from 'constants/urls'
import { useBrowserContext } from 'store/browser-context'
import ViewHomePageSection from './customize-home-page/sections/ViewHomePageSection'



const InnerCustomizeHomePage = () => { 
  const {homePageMode, setHomePageMode, selectedDevice, setselectedSectionId} = useCustomizeHomePageContext()
  const [loading, setLoading] = useState(false)
  const browserContext = useBrowserContext()
  const { setGlobalMessageA } = browserContext
  const toggleAutoHomePage=()=>{
    setLoading(true)
    axiosTr.post(
      apiUrl + '/store/toggle-auto-home-page',
      {
        store_id: localStorage.getItem('storeId'),
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      }
    ).then(response=>{
      const data = response.data
      setLoading(false)
      setHomePageMode(data.home_page_mode)
      setGlobalMessageA({
        children: translate('Store mode is updated successfully'),
        color: 'var(--successColor)',
        time: 3000
      })
    }).catch(error=>{
      console.log(error)
      setLoading(false)
      setGlobalMessageA({
        children: translate('Store mode is not updated'),
        color: 'red',
        time: 3000
      })
    })
  }
  useEffect(()=>{
    if (homePageMode) setselectedSectionId('default-section')
  }, [homePageMode])
  return (
    <div className='p-2 p-relative' style={{backgroundColor: 'var(--containerColor)'}} id='home-page' >
      <div className={'d-f g-3 flex-1' + classes['container']} style={{height: 'calc(100vh - 34px)', paddingTop: 38}}>
        <DeviceTypeArea />
        <div className='d-f align-items-center g-3' style={{position: 'absolute', top: 8, left: 6, zIndex: 2, width: 300,}}>
            <input disabled={loading} style={{scale: '0.8'}} checked={homePageMode} onChange={toggleAutoHomePage} type='checkbox' />
            <h3>{ translate('Automatic home page') }</h3>
        </div>
        <SectionsSection />
        <ViewHomePageSection key={selectedDevice} />
        <EditSection />
      </div>
    </div>
  )
}

const CustomizeHomePage=()=>(
  <CustomizeHomePageContextProvider>
    <InnerCustomizeHomePage />
  </CustomizeHomePageContextProvider>
)

export default CustomizeHomePage