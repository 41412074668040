import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { axiosTr, translate } from '../../utils/utils';
import statesFromJson from '../../json/state.json'
import { useContextSelector } from 'use-context-selector';
import { AddProductContext } from './store/add-product-context';
import ShippingByStateSection from 'components/ShippingByState';
import { apiUrl } from 'constants/urls';

let StatesWithCost = []
for (const state of statesFromJson){
    StatesWithCost.push({
        ...state,
        cost: 0,
    })
}

const ShippingSection=forwardRef((props, ref)=>{
    const shippingCostByState = useContextSelector(AddProductContext, state=>state.productInfo.shippingCostByState)
    const defaultStates = shippingCostByState && statesFromJson.map(state=>{
        const newState = shippingCostByState.find(elem=>elem.id === state['id'])
        if (newState) return newState
        else return ({
            id: state['id'],
            code: state['code'],
            label: state['name'],
            cost: null,
            costToHome: null
        })
    })
    const [states, setStates]= useState(defaultStates)
    useImperativeHandle(ref, ()=>({
        shippingData: {
            shippingCostByState : useDefault ? undefined : states && states.filter(state=>(state.cost !== null || state.costToHome !== null)).map(elem=>({
                id:elem.id, 
                cost: elem.cost, 
                costToHome: elem.costToHome
            })),
            askForAddress: false,
            useDefaultShipping: useDefault,
        }
    }))

    const defaultUseDefault = useContextSelector(AddProductContext, state=>state.productInfo.useDefaultShipping)
    const [useDefault, setUseDefault] = useState(defaultUseDefault)

    useEffect(()=>{
        if(!useDefault && !states){
            setDisableSave(true)
            axiosTr.get( 
                apiUrl + '/store/get-default-shipping-costs?store_id=' + localStorage.getItem('storeId'),
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }
            ).then(response=>{
                setStates(statesFromJson.map(elem=>{
                    let newState = response.data.find(stt=>stt.id === elem.id)
                    if (!newState) newState={
                        id: elem.id,
                        cost: null,
                        costToHome: null
                    }
                    return({
                        ...newState,
                        name: elem.name,
                        code: elem.code,
                    })
                }))
                setDisableSave(false)
            }).catch(err=>{
                setUseDefault(true)
                setDisableSave(false)
            })
            
        }
    }, [useDefault])

    const setDisableSave = useContextSelector(AddProductContext, state=>state.setDisableSave)
    return(
            <div className={ 'g-1 container column m-3 m-sm-0'}>
                <div className='my-2'>
                    <div className='g-2 d-f align-center px-2'>
                        <input type='checkbox' checked={useDefault} onChange={(e)=>setUseDefault(e.target.checked)} style={{zoom: '0.8'}} />
                        <h3 className='color-primary'>{ translate('Use default shipping cost') }</h3>
                    </div>
                </div>
               { !useDefault &&  <div className='p-2'>
                    <div className='column g-2'>
                        <h3 className='color-primary'>{ translate('Shipping cost') } ({ translate('DA') })</h3>
                    </div>
                    { states && <ShippingByStateSection {...{states, setStates}} />}
                </div>}
            </div>            
    )
})

export default ShippingSection