import React from 'react'
import procolisImage from '../assets/icons/settings/procolis.png'
import { Link } from 'react-router-dom'

const ShippingCompanies = () => {
  return (
    <div className='d-flex flex-1'>
        <div className='flex-1 d-flex p-2' style={{maxWidth: 1920, margin: '0 auto'}}>
            <div className='container flex-1 p-1'>
                <div className='d-flex'>
                    <Link to={'procolis'} className='container p-2 scale-on-hover' style={{maxWidth: 380}}>
                        <img src={procolisImage} width={120} height={120} className='flex-shrink-0' style={{objectFit: 'contain'}} />
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ShippingCompanies