import LazyImage from 'components/LazyImage'
import LazyLoadCustiom from 'components/LazyLoadCustiom'
import MotionItem from 'components/Motionitem'
import Shirt from 'pages/store/pages/customize-home-page/components/Shirt'
import { useCustomizeHomePageContext } from 'pages/store/pages/customize-home-page/store/CustomizeHomePageContext'
import React from 'react'
import { translate } from 'utils/utils'


const ProductCard=({product, selectedSectionDesign})=>{
    const {visionMode, isMobile} = useCustomizeHomePageContext()
    const productsDesign =  selectedSectionDesign.products
    
    const {
        gap,
        bordersRounded,
        borderColor: borderColorObject,
        backgroundColor: backgroundColorObject,
        borderWidth,
        product: {
            width,
            image: {
                aspectRatio,
                objectFit
            },
            title: {
                size : productTitleSize,
                color: titleColorObject
            },
            price: {
                size : productPriceSize,
                color: priceColorObject
            }
        }
    } = productsDesign

    const backgroundColor = backgroundColorObject[visionMode] === '#00000000' ? selectedSectionDesign.backgroundColor[visionMode] : backgroundColorObject[visionMode]
    const borderColor = borderColorObject[visionMode]
    const titleColor = titleColorObject[visionMode]
    const priceColor = priceColorObject[visionMode]

    const borderRadius = isMobile ? 4 : 8
    return(
        <MotionItem style={{padding: gap/2, width, minWidth: 152}}>
            <div  
                style={{ 
                    overflow: 'hidden', 
                    borderRadius: bordersRounded ? borderRadius : undefined, 
                    border: `${borderWidth}px solid ${borderColor}`,
                }}>
                    <div
                        style={{
                            backgroundColor: backgroundColor,
                        }}
                    >
                        { product.image !== null && <LazyImage 
                            style={{
                                width: '100%', 
                                aspectRatio: aspectRatio, 
                                objectFit,
                            }} 
                            src={product.image}
                        />}
                        {product.image === null && <Shirt aspectRatio={aspectRatio} fill={'var(--primaryColor)'} />}
                        <div className='px-1'>
                            <h4 className='cut-text' style={{fontSize: productTitleSize, color: titleColor}}>{ product.title }</h4>
                            {  product.price  ? 
                                <div className='d-f justify-content-between'>
                                    <h4 style={{color: priceColor, fontSize: productPriceSize}}>{ product.price } {translate('DA')} </h4> 
                                    { product.original_price && <h4 style={{fontSize: productPriceSize, color: 'var(--greyColor)', textDecoration:'line-through'}}>{ product.original_price } {translate('DA')} </h4> }
                                </div>:
                                <h4 style={{color: 'red', fontSize: productPriceSize}} >{ product.price } {translate('No price')} </h4> 
                            }
                        </div>
                    </div>  
                        
            </div>
        </MotionItem>
            
)}

export default ProductCard