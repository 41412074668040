import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import useGetCurrentScreenWidth from "hooks/useGetScreenWidth";

const style =`
 .list {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .list>:first-child{
    border: 2px solid var(--primaryColor);
    border-radius: 4px;
  }
  
  .item {
    cursor: grab;
        user-select: none;
  }
  .dragged{
    cursor: grabbing;
  }
`


export default function Drag2D({items, setItems, cardProps, ImageCard, keyExtractor}) {

  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
  };

  const screenWidth = useGetCurrentScreenWidth()
  return (
    <>
      <style>{style}</style>
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
        key={screenWidth}
      >
        {items.map((item, index) => (
          <SortableItem key={keyExtractor(item)}>
          <div className="item">
              <ImageCard file={item} index={index} {...cardProps}/>
          </div>
          </SortableItem>
        ))}
      </SortableList>
    </>
    
  );
}
