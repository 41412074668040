import React, { useMemo } from 'react'
import classes from './SidebarContent.module.css'
import SidebarComponent from './SidebarComponent'
import { translate } from '../../../utils/utils'
import { useUserContext } from '../../../store/user-context'
import { useBrowserContext } from 'store/browser-context'
import Loader from 'components/Loader'
import useLogout from 'hooks/useLogout'



const SidebarContent = () => {
  const sidebarElemnts = useMemo(()=>[
    {
        label: translate("Dashboard"),
        iconClass: "fa-solid fa-house",
        link: 'dashboard',
    },
    {
        label: translate("Orders"),
        iconClass: "fa-solid fa-box",
        link: 'orders',
        childrenData:[
            {
                label: translate("Orders"),
                iconClass: "fa-solid fa-box",
                link: 'orders',
            },
            {
                label: translate("Obandoned orders"),
                iconClass: "fa-solid fa-box",
                link: 'orders/abandoned-orders',
            },
        ]
    },
    {
        label: translate("Products"),
        iconClass: "fa-solid fa-tags",
        link: 'products',
        childrenData:[
          {
              label: translate("Your product"),
              iconClass: "fa-solid fa-tags",
              link: 'products',
          },
          {
            label: translate("Add product"),
            iconClass: "fa-solid fa-square-plus",
            link: 'products/add',
        },
      ]
    },
    {
        label: translate("Store"),
        iconClass: "fa-solid fa-store",
        link: 'store',
        childrenData:[
          {
              label: translate("General design"),
              iconClass: "fa-solid fa-palette",
              link: 'store/design',
          },
          {
              label: translate("Categories"),
              iconClass: "fa-solid fa-list",
              link: 'store/categories',
          },
          {
            label: translate("Customize pages"),
            iconClass: "fa-solid fa-edit",
            link: 'store/customize-home-page',
        },
      ]
    },
    {
      label: translate("Apps"),
      iconClass: "fa-solid fa-puzzle-piece",
      link: 'apps',
    },
    {
        label: translate("Statistics"),
        iconClass: "fa-solid fa-chart-line",
        link: 'stats',
    },
    {
        label: translate("Credit scoore"),
        iconClass: "fa-solid fa-credit-card",
        link: 'credit-scoore',
    },
  ], [])
  
  const sidebarBottomElemnts =useMemo(()=>[
    {
        label: translate("Settings"),
        iconClass: "fa-solid fa-gear",
        link: 'settings',
    },
    {
        label: translate("Support"),
        iconClass: "fa-solid fa-headset",
        link: 'support',
    },
    
  ], [])

  const {userData, storeCredit} = useUserContext()
  const {setOpenSideBar} = useBrowserContext()

  const {logout, loading} = useLogout()
  const logoutHandler = async()=>{
    const reponse = await logout()
    if (reponse) setOpenSideBar(false)
  }

  const {langTerms} = useBrowserContext()
  return (
    <div className={classes.sidebar} >
      <div className={classes['title-container']}>
      <i className={ classes['title__icon'] + ` fa-solid fa-arrow-${langTerms['left']}`} onClick={setOpenSideBar.bind(this, false)}></i>
        <i className="fa-solid fa-user" style={{fontSize: 22}}></i>
        <h3>{userData.full_name}</h3>
        { storeCredit !== null && <h4 style={{flexShrink: 0, color: 'var(--successColor)'}} className='no-select'> { storeCredit } { translate('DA') }</h4>}
        { storeCredit === null && <div style={{ backgroundColor: 'var(--greyColor)', borderRadius: 4, height: 16, width: 60 }} />}
      </div>
      <hr/>
      <div>
        {
          sidebarElemnts.map(elem=><SidebarComponent {...elem} key={elem['link']} onClick={setOpenSideBar.bind(this, false)} />)
        }
      </div>
      <div className={classes['bottom-elements-container']}>
        {
          sidebarBottomElemnts.map(elem=><SidebarComponent {...elem} key={elem['link']} onClick={setOpenSideBar.bind(this, false)}  />)
        }
        <div 
          disabled={loading} 
          onClick={logoutHandler} 
          className={ classes['logout'] +  ' d-f g-2 align-center cursor-pointer'}
        >
          <i className="fa-solid fa-right-to-bracket" style={{fontSize: 20, padding: 12}} /> 
          <h4 style={{flexGrow:1, color:'inherit'}}>{translate('Logout')}</h4>
          { loading && <Loader diam={22}/>}
        </div>
      </div>
    </div>
  )
}

export default SidebarContent