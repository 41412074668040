import { Reorder } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { ReorderItemWithDrag } from 'components/ReorderItemWithDrag'
import IconWithHover from 'components/IconWithHover'
import { axiosTr, deleteImage, deleteImages, translate } from 'utils/utils'
import { useCustomizeHomePageContext } from '../store/CustomizeHomePageContext'
import Img from 'components/Img'
import Button from 'components/Button'
import MotionItem from 'components/Motionitem'
import DialogComponent from 'components/tags/Dialog'
import { createPortal } from 'react-dom'
import { apiUrl } from 'constants/urls'
import { useUserContext } from 'store/user-context'
import Accordiant from 'components/Accordiant'
import swipeImage from '../../../../../assets/icons/store/home-customization/swipe.png'
import imageImage from '../../../../../assets/icons/store/home-customization/image.png'
import textImage from '../../../../../assets/icons/store/home-customization/text.png'
import containerImage from '../../../../../assets/icons/store/home-customization/layers.png'
import categoryImage from '../../../../../assets/icons/store/home-customization/layers-black.png'
import { defaultDesignMobile, defaultDesignPC, swiperDefaultDesign } from '../constants'
import UploadImageButton from 'components/UploadImageButton'
import OptionsContainer from 'components/OptionsContainer'

const AddProductsDialog=({show, setShow, section,  nonSelectedProducts, setNonSelectedProducts})=>{

  const {setSections} = useCustomizeHomePageContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [page, setPage] = useState(1)
  const [numPages, setNumPages]= useState([])
  const [hasPrev, setHasPrev]=useState(false)
  const [hasNext, setHasNext]=useState(false)


  const {userData} = useUserContext()

  const addProductToSection=(product)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === section.id)
      currentSection.products= [...currentSection.products, product]
      setNonSelectedProducts(nonSelectedProducts=>nonSelectedProducts.filter(elem=>elem.product_id !== product.product_id))
      return newSections
    })
  }

  useEffect(()=>{
    if (nonSelectedProducts?.length === 0){
      if (numPages === 1) return
      else if(page === numPages) setPage(page - 1)
      else setPage(page + 1)
    }
  }, [nonSelectedProducts])

  const isTopCatgory = section.type === 'category'
  const getNonSelctedProducts=async()=>{
    setLoading(true)
    setError(false)
    try{
      const response = await axiosTr.post(
        apiUrl + ( isTopCatgory ? '/store/non-selected-category-products' : '/store/non-selected-container-products'),
        {
          excluded_products : [...section.products, ...(nonSelectedProducts || [])].map(product=>product.product_id),
          domain: userData.domain,
          category_id: isTopCatgory ? section.id.split('-')[1] : undefined,
          page
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      )
      setNonSelectedProducts(response.data.products)
      setNumPages(response.data.numPages)
      setHasNext(response.data.hasNext)
      setHasPrev(response.data.hasPrev)
    }catch(error){
      console.log(error)
      setError(true)
    }
    setLoading(false)
  }

  useEffect(()=>{
    if(!nonSelectedProducts && show)  getNonSelctedProducts()
  }, [show])

  useEffect(()=>{
    if(show) getNonSelctedProducts()
  }, [page])
  return(
    <DialogComponent open={show} close={()=>setShow(false)}> 
      <div className='container column' style={{width: '80vw', height: '80vh'}}>
        <div className='p-2 flex-1' style={{overflowY: 'auto'}}>
          <h3>{ translate('{category} products', {'category': section.title}) }</h3>
          <hr className='my-2'/>
          <div className='d-f g-2 flex-wrap' style={{justifyContent: 'center'}}>
            {nonSelectedProducts && nonSelectedProducts.map(product=>(
              <div key={product.product_id} onClick={()=>addProductToSection(product)} className='container column scale-on-hover' style={{width: 122}}>
                <Img
                  src={product.image}
                  style={{
                    width: 120,
                    height: 120,
                    objectFit: 'cover'
                  }}
                />
                <h4 className='px-1 cut-text'>{ product.title }</h4>
              </div>
            ))}
          </div>
        </div>
        { (hasNext || hasPrev) && 
          <div disabled={loading} className='p-2 d-f g-3 justify-center' style={{alignItems: 'start'}}>
              <Button disabled={!hasPrev} onClick={()=>{setPage(page-1)}}><i style={{fontSize: 22}} className='fa-solid fa-chevron-left py-1'/></Button>
              <div className='d-f g-2 f-wrap justify-center'>
                  {Array.from({ length: numPages }, (_, i) => i).map(i=>(
                      <Button key={i} outline={page === i+1} onClick={()=>{setPage(i + 1)}}>
                          {i+1}
                      </Button>
                  ))}
              </div>
              <Button disabled={!hasNext}  onClick={()=>{setPage(page+1)}}><i style={{fontSize: 22}} className='fa-solid fa-chevron-right py-1'/></Button>
          </div>
      }
      </div>
    </DialogComponent>
  )
}

const CategorySubSection = ({section})=>{
  const {setSections} = useCustomizeHomePageContext()
  const [show, setShow]=useState(false)
  const products = section.products
  const [nonSelectedProducts, setNonSelectedProducts] = useState(null)

  const updateSectionProducts=(newProducts)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id == section.id)
      currentSection.products = newProducts
      return newSections
    })
  }
  const deleteProduct=(product)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id == section.id)
      currentSection.products = currentSection.products.filter(elem=> elem.product_id !== product.product_id)
      setNonSelectedProducts( nonSelectedProducts => [product, ...(nonSelectedProducts || [])])
      return newSections
    })
  }

  return (
    <div >
      <Reorder.Group axis="y" onReorder={updateSectionProducts} values={products} className='g-2 column p-1'>
        {products.map(product=>
          <ReorderItemWithDrag 
            item={product} 
            key={product.product_id} 
            className={'container'}
            disableDrag={section.show_latest_products}
          >
            <div className='d-f g-3 align-items-center flex-1 p-1' style={{maxWidth: '100%'}}> 
              <Img 
                src={product.image} 
                style={{
                  width: 32,
                  height: 32,
                  objectFit: 'cover'
                }}
                className='border'
              />
              <div className='flex-1 p-relative' style={{alignSelf: 'stretch'}}>
                <h4 className='cut-text' style={{width: '100%', position: 'absolute', top: 0}}>{ product.title }</h4>
              </div>
              { !section.show_latest_products && <IconWithHover  className={`fa-solid fa-trash color-red`} onClick={deleteProduct.bind(this, product)} />}
            </div>
        </ReorderItemWithDrag>                
        )}
      </Reorder.Group>
      { !section.show_latest_products && <MotionItem className='p-1'>
        <Button outline className='col-12 g-3 d-f' style={{padding: 2}} onClick={()=>setShow(true)}>
          <i className='fa-solid fa-square-plus' style={{fontSize: 24}} />
          { translate('Add product') }
        </Button>
      </MotionItem>}
      {
        createPortal(  
          <AddProductsDialog {...{show, setShow, section, nonSelectedProducts, setNonSelectedProducts}} />
          , document.getElementById('home-page')
        )
      }
      
    </div>
  )
}

const SwiperImageRow=({removeImage, updateImage, imageObj, index})=>{
  const [deletingImage, setDeletingImage] = useState(false) 
  
  const deleteImg=async()=>{
    setDeletingImage(true)
    const response  = await deleteImage(imageObj.url, 'store/home-page/swiper-image')
    setDeletingImage(false)
    if (!response) return
    removeImage(imageObj.url)
  }
  return(
    <div className='d-f g-3 align-items-center flex-1 p-1' style={{maxWidth: '100%'}} disabled={deletingImage}> 
      <UploadImageButton 
        {...{
          image: imageObj.url, 
          imageChangeHandler: (newImage)=>updateImage(index, {...imageObj, url: newImage}), 
          size: 40, 
          url: '/upload-swiper-image', 
          outputFormat: null, 
          resolution: 2024,  
          type: 'store/home-page/swiper-image', 
        }}
      />

      <input defaultValue={imageObj.link} placeholder={translate('Link for the image')} onBlur={e=>updateImage(index, {link: e.target.value.trim()})} className='box-input flex-1' style={{padding: '4px'}}  />
      <IconWithHover className={`fa-solid fa-trash color-red`} onClick={deleteImg} />
    </div>
)
}

const SwiperSubSection = ({section})=>{
  const {setSections, selectedSectionId} = useCustomizeHomePageContext()
  const imageObjects = section.imageObjects
  const addImage = (imageObj)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === selectedSectionId)
      currentSection.imageObjects = [...currentSection.imageObjects, imageObj]
      return newSections
    })
  }

  const removeImage = async(index)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === selectedSectionId)
      currentSection.imageObjects.splice(index, 1)
      return newSections
    })
  }
  const updateImage = (index, update)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === selectedSectionId)
      currentSection.imageObjects[index] = {
        ...currentSection.imageObjects[index],
        ...update
      }
      return newSections
    })
  }
  const reorderImageObjects=(newImageObjects)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === selectedSectionId)
      currentSection.imageObjects = newImageObjects
      return newSections
    })
  }
  const disabled = imageObjects.map(image=>image.url).includes('')
  
  return (
    <div>
      { imageObjects.length > 0 && <Reorder.Group axis="y" onReorder={reorderImageObjects} values={imageObjects} className='g-2 column p-1'>
        {imageObjects.map((imageObj, index)=>
          <ReorderItemWithDrag 
            item={imageObj} 
            key={imageObj.url} 
            className={'container'}
          >
            <SwiperImageRow {...{removeImage: removeImage.bind(this, index), updateImage, imageObj, index}}  />
        </ReorderItemWithDrag>
        )}
      </Reorder.Group>}
      <MotionItem className='p-1' disabled={disabled}>
        <Button outline className='col-12 g-3 d-f' style={{padding: 2}} onClick={()=>addImage({
          url: '',
          link: ''
        })}>
          <i className='fa-solid fa-square-plus' style={{fontSize: 24}} />
          { translate('Add Image') }
        </Button>
      </MotionItem>
    </div>
  )
}

const ShowLatestProducts=({section})=>{
  const showLatestProducts = !!section.show_latest_products
  const [lastestProductsCount, setLatestProductsCount] = useState(section.lastest_products_count || 12)
  const [loading, setLoading] = useState(false)

  const {setSections} = useCustomizeHomePageContext()
 
  const updateLatestProductsCount = (showLatestProducts)=>{
    setLoading(true)
      // change in server
        axiosTr.post(
          apiUrl + '/store/toggle-home-page-section-show-latest-products',
          {
            section_id: section.id,
            store_id: localStorage.getItem('storeId'),
            lastest_products_count: lastestProductsCount,
            show_latest_products: showLatestProducts
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/json'
            }
          }
        ).then(response=>{
          setSections(sections=>{
            const newSections = [...sections]
            const currentSection = newSections.find(sec=>sec.id === section.id)
            if (response.data.products) currentSection.products = response.data.products
            currentSection.show_latest_products = response.data.show_latest_products
            currentSection.lastest_products_count = lastestProductsCount
            return newSections
          })
          setLoading(false)
        }).catch(error=>{
          console.log(error)
          setLoading(false)
        })
  }

  const toggleShowLatestProducts=(e)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === section.id)
      currentSection.show_latest_products = e.target.checked
      return newSections
    })
  }

  const showCheck = showLatestProducts && (section.lastest_products_count !== lastestProductsCount)
  return(
    <div className='p-2 d-f g-3 align-items-center' disabled={loading}>
      <input checked={showLatestProducts} onChange={toggleShowLatestProducts} type='checkbox' style={{zoom: 0.8}} />
      <h4>{ translate('Show latest products') }</h4>
      { showLatestProducts && <>
        <input defaultValue={lastestProductsCount} min={1} onChange={e=>setLatestProductsCount(Number(e.target.value))} type='number' className='box-input' style={{width: 52, height: 26, padding: '0 4px'}} max={20} />
        { showCheck && <Button onClick={()=>updateLatestProductsCount(true)} style={{borderRadius: '50%', aspectRatio: 1, padding: 0, width: 26}}>
          <i className='fa-solid fa-check' />
        </Button>}
      </>}
      {}
    </div>
  )
}

const SectionParameters=({section, deleteSection})=>{
  const {selectedSectionId, setselectedSectionId, selectedDevice, setSections }= useCustomizeHomePageContext()
  const [showExtention, setShowExtention] = useState(false)

  const toggleDevice = (device)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === section.id)
      if (currentSection.device.includes(device)){
        currentSection.device = currentSection.device.filter(dev=>dev !== device)
      }
      else{
        currentSection.device = [...currentSection.device, device]
      }
      return newSections
    })
  }

  useEffect(()=>{
    if (selectedSectionId !== section.id) setShowExtention(false)
  }, [selectedSectionId])

  useEffect(()=>{
    if (showExtention) setselectedSectionId(section.id)
  }, [showExtention])

  const [show, setShow] = useState(false)

  return(
    <div>
      <OptionsContainer style={{width: 312}} bgColor={false} show={show} setShow={setShow} 
        alwaysShown={  <Button style={{borderRadius: 8, padding: '0 4px'}} onClick={()=>setShow(!show)}>  
            <i className='fa-solid fa-ellipsis' style={{fontSize: 22}} />
          </Button> 
        }
        className='mt-1'
        >
          <div className='d-flex g-3 align-items-center p-2' style={{justifyContent: 'flex-end'}}>
            <Button onClick={()=>toggleDevice('mobile')} outline={section.device.includes('mobile')} style={{padding: '4px 9px'}}><i className='fa-solid fa-mobile-screen' /></Button>
            <Button onClick={()=>toggleDevice('PC')} outline={section.device.includes('PC')} style={{padding: '4px 4px'}} ><i className='fa-solid fa-laptop'/></Button>
            {section.type !== 'category' && <IconWithHover onClick={deleteSection} className={`px-1 fa-solid fa-trash color-red`} />}
            {section.type === 'category' && <IconWithHover size={16} onClick={deleteSection} className={`px-1 fa-solid fa-eye-slash`} />}
          </div>
         { (section.type === 'products-container' || section.type === 'category') && <ShowLatestProducts section={section} /> }
      </OptionsContainer>
   </div>
  )
}

const SectionTop = ({section})=>{
  const {setselectedSectionId, setSections, selectedDevice, homePageMode} = useCustomizeHomePageContext()
  const wrongDeviceSwiper = !section.device.includes(selectedDevice)
  const [deletingSection, setDeletingSection] = useState(false) 
  const deleteSection=async()=>{
    if (section.type === 'category'){
      setSections((sections) => {
        const newSections = [...sections];
        const currentSection = newSections.find((sec) => sec.id === section.id);
        currentSection.active = false;
        return newSections;
      });
    }
    else if (section.type === 'swiper') {
      setDeletingSection(true)
      const response = await deleteImages(section.imageObjects.map((obj) => obj.url), 'store/home-page/swiper-image');
      setDeletingSection(false)
      setSections((sections) => {
        const newSections = [...sections];
        if (response) {
          return newSections.filter((sec) => sec.id !== section.id);
        } else {
          return newSections;
        }
      });

    } 
    else {
      // For other cases, handle it here
      setSections((sections) => sections.filter((sec) => sec.id !== section.id));
    }
  }

  
  const image = (
    section.type === 'category' ? categoryImage : 
    section.type === 'products-container' ? containerImage : 
    section.type === 'swiper' ? swipeImage :
    section.type === 'rich-text' ? textImage:
    section.type === 'image-with-link' ? imageImage : 
    ''
  )

  const updateTitle=(e)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === section.id)
      currentSection.title = e.target.value.trim()
      return newSections
    })
  }

  return(
      <div className='d-f align-items-center flex-1' disabled={deletingSection}>
        <div disabled={wrongDeviceSwiper} className='cursor-pointer d-f g-2 align-items-center flex-1' onClick={()=>setselectedSectionId(section.id)}>
          <Img 
            src={image}
            width={30}
          />
          <div style={{flex: 1, position: 'relative', height: 52, padding: '8px 8px 8px 0'}}>
            <input className='flex-1 py-2 color-primary cut-text box-input' style={{height: '100%', padding: '0 4px', fontWeight: 'bold', outline: 'none'}} defaultValue={section.title} onBlur={updateTitle} />
          </div>
        </div>
        { !homePageMode && <SectionParameters {...{section, deleteSection}} />}
      </div>
  )
}

const Section =({section})=>{
  const {selectedSectionId, setselectedSectionId, selectedDevice, homePageMode }= useCustomizeHomePageContext()
  const [showExtention, setShowExtention] = useState(false)
  useEffect(()=>{
    if (selectedSectionId !== section.id) setShowExtention(false)
  }, [selectedSectionId])

  useEffect(()=>{
    if (showExtention) setselectedSectionId(section.id)
  }, [showExtention])

  const  isProductContainer = section.type === 'products-container'
  const  isCatgeory = section.type === 'category'
  const isSwiper = section.type === 'swiper' 

  const wrongDeviceSwiper = !section.device.includes(selectedDevice)
  const showAccordiant = !((isProductContainer || isCatgeory ) && section.show_latest_products) && !homePageMode
  return(
    <div className='mb-2 p-relative'>
      <ReorderItemWithDrag 
          item={section} 
          key={section.id} 
          className={'container primary-light-on-hover'}
          style={{
              backgroundColor: selectedSectionId === section.id ? 'var(--background200Color)' : undefined,
          }}
          disableDrag={homePageMode}
        >
          <div className='flex-1 d-f align-items-center' >
            { showAccordiant && <Accordiant setChecked={setShowExtention} checked={!wrongDeviceSwiper && showAccordiant && showExtention} disabled={wrongDeviceSwiper} />}
            <SectionTop section={section} disabled={wrongDeviceSwiper} />
          </div>
      </ReorderItemWithDrag> 
      {
        showExtention && (
          <div style={{backgroundColor: 'var(--background200Color)'}} className='border rounded mt-1'>
            { (isCatgeory || isProductContainer) && <CategorySubSection section={section} />}
            { isSwiper && <SwiperSubSection section={section} /> }
          </div>
        )
      }
    </div>
        
  )
}

const AddCategory=({toggleCategory, setSelectedSectionType})=>{
  const {sections} = useCustomizeHomePageContext()
  const nonSelectedCategories = sections.filter(sec=>!sec.active)
  return(
    <div>
      <div className='d-f align-items-center'>
        <IconWithHover iconClass='fa-solid fa-chevron-left px-2' size={24} onClick={()=>setSelectedSectionType(null)} />
        <h3>{ translate('Add category') }</h3>
      </div>
      <hr className='my-2'/>
      <div style={{ display: 'flex', justifyContent: 'center', gap: 8, flexWrap: 'wrap'}}>
        {
          nonSelectedCategories.map(category=>(
            <div key={category.id} onClick={()=>toggleCategory(category)} className='container column scale-on-hover' style={{width: 122}}>
              <Img
                src={category.image}
                style={{
                  width: 120,
                  height: 120,
                  objectFit: 'cover'
                }}
              />
              <h4 className='px-1 cut-text'>{ category.title }</h4>
            </div>
          ))
        }
        {
          nonSelectedCategories.length === 0 && <h2>{ translate('No categories here') }</h2>
        }
      </div>
    </div>
  )
}

const defaultSectionTypes = [
  {
    id: 1,
    label: translate('Category'),
    value: 'category',
    image: categoryImage
  },
  {
    id: 2,
    label: translate('Container'),
    value: 'products-container',
    image: containerImage
  },
  {
    id: 3,
    label: translate('Swiper'),
    value: 'swiper',
    image: swipeImage

  },
  {
    id: 4,
    label: translate('Rich text'),
    value: 'rich-text',
    image: textImage
  },
  {
    id: 5,
    label: translate('Image with link'),
    value: 'image-with-link',
    image: imageImage
  },

]

const SectionsTab=({setSelectedSectionType})=>{
  return(
    <div>
        <div className='d-f align-items-center'>
          <h3>{ translate('Sections') }</h3>
        </div>
        <hr className='my-2'/>
        <div className='d-f g-3 flex-wrap' style={{justifyContent: 'center'}}>
        {
            defaultSectionTypes.map(type=><div key={type.id} className='container p-2 scale-on-hover' onClick={()=>setSelectedSectionType(type.value)} >
              <Img 
                src={type.image}
                width={100}
                height={100}
                style={{borderRadius: 8}}
              />
              <h4>{ type.label }</h4>
            </div>)
          }
        </div>
      </div>
  )
}

const AddSectionDialog=({show, setShow})=>{

  const {setSections, sections, selectedDevice, setselectedSectionId} = useCustomizeHomePageContext()

  const [selectedSectionType, setSelectedSectionType] = useState(null)

  useEffect(()=>{
    if (!show) setSelectedSectionType(null)
  }, [show])



  const toggleCategory=(section)=>{
    setSections(sections=>{
      const newSections = [...sections]
      const currentSection = newSections.find(sec=>sec.id === section.id)
      currentSection.active = true
      return newSections
    })
  }    

  const addSection=(section)=>{
    setSections(sections=>[...sections, section])
    setselectedSectionId(section.id)
  }

  useEffect(()=>{
    if (selectedSectionType === 'swiper'){
      let lastSwiper = sections.filter(section=>(section.type === 'swiper'))
      if (lastSwiper.length > 0) lastSwiper = lastSwiper[lastSwiper.length - 1]
      else lastSwiper = null
      let id = 1
      if (lastSwiper) id = Number(lastSwiper.id.split('-')[1]) + 1

      addSection({
        id: `Swiper-${id}`,
        title: translate('Swiper {id}', {id: id}),
        imageObjects: [],
        design: {...swiperDefaultDesign[selectedDevice]},
        type: "swiper",
        active: true,
        device: [selectedDevice]
      })
      setSelectedSectionType(null)
      setShow(false)
    }
    if (selectedSectionType === 'products-container'){
      let productsContainer = sections.filter(section=>(section.type === 'products-container'))
      if (productsContainer.length > 0) productsContainer = productsContainer[productsContainer.length - 1]
      else productsContainer = null
      let id = 1
      if (productsContainer) id = Number(productsContainer.id.split('-')[2]) + 1

      addSection({
        id: `products-container-${id}`,
        title: translate('Conatiner {id}', {id: id}),
        products: [],
        design: {
          'mobile': {...defaultDesignMobile},
          'PC': {...defaultDesignPC},
        },
        type: "products-container",
        active: true,
        device: ['PC', 'mobile']
      })
      setSelectedSectionType(null)
      setShow(false)
    }
  }, [selectedSectionType])

  return(
    <DialogComponent open={show} close={()=>setShow(false)}> 
      <div className='container column' >
        <div className='p-2' style={{overflowY: 'auto', width: '80vw', height: '80vh'}}>  
          { !selectedSectionType && <SectionsTab setSelectedSectionType={setSelectedSectionType} /> }
          { selectedSectionType === 'category' && <AddCategory setSelectedSectionType={setSelectedSectionType} toggleCategory={toggleCategory} />}
        </div>
      </div>
    </DialogComponent>
  )
}

const AddSection=()=>{
  const [show, setShow] = useState(false)
  const [nonSelectedCategories, setnNonSelectedCategories] = useState(null)
  return(
    <>
      <Button 
        className='col-12 g-3'
        onClick={()=>setShow(true)}
      >
        <i className='fa-solid fa-square-plus' style={{fontSize: 24}} />
        { translate('Add section') }
      </Button>
      { document.getElementById('home-page') &&
        createPortal(  
          <AddSectionDialog {...{show, setShow, nonSelectedCategories, setnNonSelectedCategories}} />
          , document.getElementById('home-page')
        )
      }
    </>
  )
}

const SectionsSection = () => {
  const {setselectedSectionId, setSections, sections, selectedSectionId, homePageMode }= useCustomizeHomePageContext()
  return (
    <div style={{minWidth: 360, position: 'sticky', top: 64, overflowY: 'auto'}} className='flex-1 no-select'>
      <style>{`
      [id^='Swiper']{
        will-change: unset !important;
      }
      `}</style>
      <div 
        className='container p-2 primary-light-on-hover cursor-pointer' 
        onClick={()=>setselectedSectionId('general-design')}
        style={{
            backgroundColor: (selectedSectionId === 'general-design') ? 'var(--background200Color)' : undefined
        }}
      >
        <h3>{ translate('General page design') }</h3>
      </div>
      <hr className='my-2'/>
        <div>
          <Reorder.Group axis="y" onReorder={setSections} values={sections}>
            {sections.filter(sec=>{
              if (sec.type === 'category') return sec.active
              else if (sec.type === 'swiper') return true
              else if (sec.type === 'products-container') return true
            }).map(section=><Section key={section.id} section={section} />)} 
          </Reorder.Group>
        </div>
      { !homePageMode && <>
        <AddSection />
      </>}

    </div>
  )
}

export default SectionsSection