import LazyImage from 'components/LazyImage'
import MotionItem from 'components/Motionitem'
import { useCustomizeHomePageContext } from 'pages/store/pages/customize-home-page/store/CustomizeHomePageContext'
import React from 'react'


const SwiperImageCard=({imageObject, selectedSectionDesign})=>{
    const {visionMode} = useCustomizeHomePageContext()
    
    const {
        gap,
        image: {
            border: {
                radius: borderRadius,
                color:  {
                    [visionMode] : borderColor
                },
            width: borderWidth,
            },
            aspectRatio,
            objectFit,
            width,
        }
    } = selectedSectionDesign
    return(
        <MotionItem style={{padding: gap/2, width}}>
            <div  
                style={{ 
                    overflow: 'hidden', 
                    borderRadius: borderRadius, 
                    border: `${borderWidth}px solid ${borderColor}`,
                    display: 'flex'
                }}
            >
                    
                    <LazyImage
                    style={{
                        width: '100%', 
                        aspectRatio: aspectRatio, 
                        objectFit,
                    }} 
                    src={imageObject.url}
                />                     
            </div>
        </MotionItem>
            
)}

export default SwiperImageCard