import { useCustomizeHomePageContext } from 'pages/store/pages/customize-home-page/store/CustomizeHomePageContext'
import React from 'react'
import ProductCard from './simple-swiper/components/ProductCard'
import SwiperImageCard from './simple-swiper/components/SwiperImageCard'


const SimpleSwiper = ({section}) => {
    const {selectedDevice} = useCustomizeHomePageContext()
    const isSectionProductsContainer = section.type === 'products-container'|| section.type === 'category'
    const isSectionSwiper = section.type === 'swiper'

    const selectedSectionDesign = 
    isSectionProductsContainer ? section.design[selectedDevice] :
    isSectionSwiper ? section.design:
    {}

    const justifyContent = isSectionProductsContainer ? selectedSectionDesign.products.justifyContent :
                           isSectionSwiper ? selectedSectionDesign.justifyContent : 
                           undefined 
    
    // console.log(section.id, section.imageObjects) 
  return (
    <div className='d-f flex-wrap' style={{
        justifyContent: justifyContent
    }}>
        {
          isSectionProductsContainer && section.products.map(product=><ProductCard key={product.product_id} product={product} selectedSectionDesign={selectedSectionDesign} />)
        }
        {
          isSectionSwiper && section.imageObjects.map(imageObj=><SwiperImageCard key={imageObj.url} imageObject={imageObj} selectedSectionDesign={selectedSectionDesign} />)
        }
    </div>
  )
}

export default SimpleSwiper