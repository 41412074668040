import React from 'react';
import { formatDate } from 'utils/utils';

// React component
const DateFormatter = ({ date, lang, ...props}) => {
  // Validate that the input is a Date object
  if (!(date instanceof Date)) {
    return <span>Invalid Date</span>;
  }

  // Format the date and return it
  const formattedDate = formatDate(date);

  return <span {...props}>{formattedDate}</span>;
};

// Usage example:
// <DateFormatter date={new Date()} lang="en" />

export default DateFormatter;
