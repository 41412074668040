import Button from 'components/Button'
import React, { useEffect, useState } from 'react'
import { translate } from 'utils/utils'
import { Link, Outlet, useLocation } from 'react-router-dom'
import useHideHeader from 'hooks/useHideHeader'
import { useBrowserContext } from 'store/browser-context'
import IconWithHover from 'components/IconWithHover'

const options=[
    {
        label: translate('Home'),
        link: '/store/customize-home-page'
    },
    {
        label: translate('Category'),
        link: '/store/customize-category-page'
    },
    {
        label: translate('Product'),
        link: '/store/customize-product-page'
    },
    {
        label: translate('Thank you page'),
        link: '/store/thank-you-page'
    },
    {
        label: translate('Privacy policy'),
        link: '/store/privacy-policy'
    },
    {
        label:  translate('Terms of service'),
        link: '/store/terms-of-service'
    },
]

function HoverComponent({setTranslateY}) {
  
    const handleMouseEnter = () => {
        setTranslateY(0);
      // Execute any function or logic here
    };
  
    return (
        <div 
            onMouseEnter={handleMouseEnter}
            style={{width: '100vw', position: 'absolute', top: 0, zIndex: 4, background: 'var(--backgroundColor)' }} className='d-f justify-content-center cursor-pointer'
        >
            <i className='fa-solid fa-ellipsis' style={{margin: '-1px 0'}}/>
        </div>
    );
  }

const StoreCustomizePage=()=>{
    const location = useLocation()
    let link = location.pathname
    if (link[link.length - 1] === '/') link=link.slice(0, link.length - 1)
    const option = options.find(op=>op.link === link)


    const [translateY, setTranslateY] = useState(100);
    useEffect(()=>{
        setTimeout(()=>{
            setTranslateY(0)
        }, 500)
        setTimeout(()=>{
            setTranslateY(100)
        }, 1500)
    }, [])
    const handleMouseLeave = () => {
        setTranslateY(100);
      // Execute any function or logic here
    };
    useHideHeader()

    const {setOpenSideBar} = useBrowserContext()
    return (
        <>
            <div style={{position: 'absolute', top: 0, width: '100vw', zIndex: 5, background: 'var(--containerColor)', transition: 'transform 300ms', transform:`translateY(-${translateY}%)`}}>
                <div className='p-2 flex-1 p-relative d-f g-2 align-items-center justify-content-between' onMouseLeave={handleMouseLeave}>
                    <IconWithHover iconClass="fa-solid fa-bars color-primary" onClick={()=>setOpenSideBar(true)} />
                    <div className='d-f g-2' style={{overflowX: 'auto'}}>
                            {options.map(op=>(
                                <Link key={op.label} to={op.link} style={{flexShrink: 0}}>
                                    <Button style={{borderRadius: 20}} outline={op.label === option.label} >{translate(op.label)}</Button>
                                </Link>
                                    
                            ))}
                    </div>
                </div>
            </div>
            <HoverComponent setTranslateY={setTranslateY} />
                <div style={{paddingTop: 20, backgroundColor: 'var(--containerColor)'}}>
                <Outlet />
            </div>
        </>

        
    )
}
export default StoreCustomizePage